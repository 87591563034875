import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Footer from "./footer";
import Promotion from "./promotion";
import ProductLogoShowcase from "./product-logo-showcase";
import "./layout.css";
import "../styles/index.scss";
import "../styles/output.css";
import "../styles/custom.scss";
import "../styles/global.css";

import { adRefCity } from "../helpers/adRefTracking";
import Navbar from "./Navbar";

const Layout = ({ children, pageProps, footerLogoShowcase, isHomePage, customSchema, headerTransparent, headerShadow }) => {
  if (typeof window !== "undefined" && window.localStorage && !window.localStorage.getItem("landingURL")) {
    window.localStorage.setItem("landingURL", pageProps.location.href);
    window.localStorage.setItem("landingDate", new Date());
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
          allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
            edges {
              node {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow
                    }
                  }
                }
                campaign
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Navbar
            siteTitle={data.site.siteMetadata.title}
            siteDescription={adRefCity(data.site.siteMetadata.description)}
            companyInfo={data.site.siteMetadata.companyInfo}
            pageProps={pageProps}
            headerTransparent={headerTransparent}
            shadow={headerShadow}
          />

          {/* <Header
            siteTitle={data.site.siteMetadata.title}
            siteDescription={adRefCity(data.site.siteMetadata.description)}
            companyInfo={data.site.siteMetadata.companyInfo}
            mainNavigation={
              data.allContentfulNavigation.edges.filter(
                ({ node: { campaign } }) =>
                  campaign.length > 0 ? campaign[0] === 'default' : false
              )[0].node.menuItems
            }
            menus={data.allContentfulNavigation.edges}
            pageProps={pageProps}
          /> */}

          <div className="layout-container">{children}</div>
          <ProductLogoShowcase footerLogoShowcase={footerLogoShowcase} />
          <Footer isHomePage={isHomePage} />
          <Promotion />
          {!customSchema && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: `
              {
                "@id": "https://www.roachroofing.com/#HomeAndConstructionBusiness",
                "@context": "https://www.schema.org",
                "@type": "HomeAndConstructionBusiness",
                "name": "Roach Roofing",
                "url": "https://www.roachroofing.com/",
                "logo": "https://images.ctfassets.net/seqn6hyrh7p7/47ugz3TDJq3S0Vlkz6QjRt/04a537cf23f52f952ef3dfdc0e144663/roachhomeimprovement-thumb.jpg",
                "image": "https://images.ctfassets.net/seqn6hyrh7p7/47ugz3TDJq3S0Vlkz6QjRt/04a537cf23f52f952ef3dfdc0e144663/roachhomeimprovement-thumb.jpg",
                "description": "Roach Roofing is a locally owned and operated full service Remodeling contractor in Michigan, CA specializing in electric repair, service, and installation to your residential, commercial and industrial Remodeling projects.
                ",
                "address": {
                    "@type": "PostalAddress", 
                    "addressLocality": "Battle Creek",
                    "addressRegion": "MI",
                    "postalCode": "49017",
                    "addressCountry": "USA"
                },
  
                "openingHours": "Mo, Tu, We, Th, Fr 08:00-18:00",
                "contactPoint": {
                    "@type": "ContactPoint",
                    "contactType": "sales",
                    "telephone": "+1 269-420-6623"
                }
            }`,
              }}
            />
          )}
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

//classes for tailwind to include when doing purge
// pr-2
