import React, { useState } from "react";
import { Link } from "gatsby";

const breakpoints = ["40em", "52em", "64em"];

export const media = {
  small: `screen and (min-width: ${breakpoints[0]})`,
  medium: `screen and (min-width: ${breakpoints[1]})`,
  large: `screen and (min-width: ${breakpoints[2]})`,
};

function MenuItemDropdown({ item, label, source, headerTransparent }) {
  const name = label;
  const [isOpen, setIsOpen] = React.useState(false);
  const [popupVisible, setPopupVisible] = React.useState(false);

  const isSmallScreen = () => {
    return !window.matchMedia(media.small).matches;
  };
  const onGroupButtonClick = React.useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      setPopupVisible(true);
    } else {
      // ensures that sub-menu closes when no animation is available
      if (isSmallScreen()) {
        setIsOpen(false);
      }
      setPopupVisible(false);
    }
  }, [isOpen]);

  React.useEffect(() => {
    // crude implementation of animating the popup without a library
    const popupBox = document.querySelector(`[data-id="${name}-popup-box"]`);
    const onAnimationEnd = ({ animationName }) => {
      if (animationName === `zoomOutDown`) {
        setIsOpen(false);
      }
    };
    if (popupBox) {
      popupBox.addEventListener("animationend", onAnimationEnd);
      return () => {
        popupBox.removeEventListener("animationend", onAnimationEnd);
      };
    }
  }, [isOpen, name]);

  React.useEffect(() => {
    // hide menu when clicked outside
    const handleClickOutside = (event) => {
      const wrapper = document.querySelector(`[data-id="${name}-group-wrapper"]`);
      if (!isSmallScreen() && isOpen && wrapper && !wrapper.contains(event.target)) {
        onGroupButtonClick();
    
      }
   
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [name, isOpen, onGroupButtonClick]);

  const DropdownMenuItem = ({ item, type }) => {
    let itemId = null;
    let title = item.name;
    let slug = item.url;

    if (source && source == "mobilemenu") {
      return (
        <Link to={`${slug && slug.length < 2 ? "" : slug}`} className="block py-2 px-2 hover:underline font-normal">
          {item.name}
        </Link>
      );
    }
    return (
      <div className="block rounded-xl hover:bg-neutral-100 font-medium  text-[15px]  focus:outline-none focus:bg-neutral-50  text-neutral-700 hover:text-black transition-colors duration-150 ease-in">
        <Link to={`${slug}`} title={title ? title : item.name} className=" px-6 py-5 block ">
          {item.name}
        </Link>
      </div>
    );
  };

  if (source && source == "mobilemenu") {
    return (
      <div className="py-4 px-8 text-neutral-200 hover:text-white  block py-4 px-8 border-b border-white/20">
        <span className="font-semibold mb-2 block">{label}</span>
        {item.submenu[0].menuItems.map((item, i) => (
          <DropdownMenuItem key={item.id} item={item} type={item.type} />
        ))}
      </div>
    );
  }
  return (
    <div
      data-id={`${name}-group-wrapper`}
      className={`${
        headerTransparent ? "text-white hover:text-brand-200" : "text-neutral-800 hover:text-brand-600"
      } relative inline-block text-left font-normal text-base transition-colors duration-150 ease-in`}
      data-type={item.dropdownonesection}
    >
      <div>
        <div
          onClick={onGroupButtonClick}
          className={`p-3 py-7 border-transparent flex items-center transition-colors duration-150 ease-in rounded-lg focus:outline-none button-active-bhvr font-semibold text-[15px] font-display text-base border-b-[3px] 
        relative after:bg-brand-600 after:absolute after:h-[3px] after:w-0 after:bottom-[-3px] after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointer
        ${headerTransparent ? "text-white h  hover:text-brand-200" : "text-neutral-200 hover:text-white "}`}
        >
          <>
            <span>{name}</span>
            <svg
              className={`ml-1 w-4 h-4  rotate-0  duration-75 transition-transform ease ${
                popupVisible ? "transform rotate-[180deg]" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </>
        </div> 
        {isOpen && (
          <div data-id={`${name}-popup-box`} className={`bg-white rounded-xl shadow-xl navLinkBase ${popupVisible ? "nav-opened" : "nav-closed"}`}>
            <div>
              {item.submenu[0].menuItems.map((item, i) => (
 
                  <DropdownMenuItem key={item.id} item={item} type={item.type} />
                 
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MenuItemDropdown;
