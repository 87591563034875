import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition, Popover } from "@headlessui/react";
import { StaticQuery, graphql, Link } from "gatsby";
import MenuItemDropdown from "./MenuItemDropdown";
import ContactFormModal from "./ContactFormModal";
import Logo from "./logo-svg";
import Button from "./atoms/Button";
import { AnimatePresence, motion } from 'framer-motion'

import { PhoneCall, X } from "react-feather";
import { ArrowRight, BadgeCheck, ChevronRight, ChevronUp, ListOrdered, Menu, Phone, PhoneIcon, Target, TimerReset, TrendingUp, Users2 } from "lucide-react";
export const USAICON = () => (

  <svg className="w-6 h-6 mr-1" viewBox="0 0 68 49" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.27197 12.4087C2.27197 12.4087 3.33259 21.5578 4.00006 24.9999C4.64582 28.33 4.72372 32.1195 4.72372 32.1195L4.54049 35.8871L4.27874 39.8675L3.95591 43.4311L3.54583 47.1467C3.54583 47.1467 19.3731 39.0297 25.5984 39.8675C31.8281 40.7052 30.4583 39.2554 31.0211 41.3042C31.5838 43.353 32.4258 44.8418 32.4258 44.8418L51.8522 43.6785L54.4086 47.6806L67.5529 46.6735C67.5529 46.6735 64.7609 42.5934 63.9669 27.5748C63.5394 19.51 63.5569 18.2555 63.5045 15.751C63.4303 12.1005 63.4696 10.7679 63.4696 10.7679C63.4696 10.7679 56.5288 12.1005 54.2385 11.7142C53.5754 11.6013 51.8086 10.5856 51.8086 10.5856L51.6864 8.22435L50.4911 6.22766L27.6183 9.09681L2.27197 12.4087Z" fill="white"/>
  <path d="M54.3344 45.9964C54.3344 45.9964 56.8079 46.5216 60.5902 46.1222C64.3726 45.7229 66.6062 44.5249 66.6062 44.5249L67.5572 46.6735C67.5572 46.6735 64.185 49.0001 58.4264 49.0001C52.6679 49.0001 51.8564 48.3012 51.8564 48.3012L54.3344 45.9964Z" fill="#EA4335"/>
  <path d="M4.5 28.5L4.72363 32.1194C4.72363 32.1194 20.0187 26.9931 27.5266 26.5764C30.419 26.4158 31.9982 26.8846 31.9982 26.8846L31.6361 23.4946C31.6405 23.4946 26.8449 20.8692 4.5 28.5Z" fill="#EA4335"/>
  <path d="M4.54492 35.887L4.27881 39.8674C4.27881 39.8674 20.9262 34.2463 27.9412 34.0987C33.0933 33.9902 32.7531 34.7802 32.7531 34.7802L32.4041 30.5915C32.3997 30.5915 26.8898 27.5704 4.54492 35.887Z" fill="#EA4335"/>
  <path d="M33.1411 40.9742C44.2132 39.0123 53.2349 38.1702 53.837 39.3118L53.488 36.2256C53.488 36.2256 51.804 34.7107 32.8401 37.7665L33.1411 40.9742Z" fill="#EA4335"/>
  <path d="M32.7268 34.9451C42.9482 33.0439 52.6723 32.1454 53.2787 33.287L52.969 29.9448C52.969 29.9448 52.1183 28.0957 32.4258 31.7374L32.7268 34.9451Z" fill="#EA4335"/>
  <path d="M32.1028 29.0202C41.8618 26.7544 51.8782 25.7344 52.7245 26.6242L52.5195 23.5944C52.5195 23.5944 51.1758 21.7627 31.876 25.9427L32.1028 29.0202Z" fill="#EA4335"/>
  <path d="M31.6405 22.9912C45.1251 19.8139 51.3635 19.8269 52.3538 20.4216L52.175 17.292C52.175 17.292 49.8803 15.9638 31.3613 19.8313L31.6405 22.9912Z" fill="#EA4335"/>
  <path d="M30.6763 17.0662C37.5909 15.5513 50.3339 13.3072 52.0135 14.3794L51.8826 11.5232C51.8826 11.5232 49.6577 9.63942 30.1833 13.9627L30.6763 17.0662Z" fill="#EA4335"/>
  <path d="M30.2488 10.768C37.447 8.76698 50.9185 7.26946 51.7038 8.51088L51.6034 5.75893C51.6034 5.75893 51.0101 3.02 29.7559 7.44309L30.2488 10.768Z" fill="#EA4335"/>
  <path d="M0 5.5C0 5.5 1.06477 10.7898 4 25C4 25 17.9465 20.7947 23.6614 20.0828C31.3177 19.1279 31.3962 20.2174 31.3962 20.2174C31.3962 20.2174 30.1398 6.12772 28.687 2.20814C27.7578 -0.300739 23.2688 -0.869359 15.39 1.53534C7.5113 3.94005 4.18367 5.73873 0 5.5Z" fill="#5576BA"/>
  <path d="M51.813 10.5854L51.9526 13.7063C51.9526 13.7063 57.9118 14.7177 63.4697 12.7427V9.63916C63.4697 9.63916 58.4615 11.7183 51.813 10.5854Z" fill="#EA4335"/>
  <path d="M52.1182 16.5495L52.3101 19.5359C52.3101 19.5359 57.9596 20.5342 63.5698 18.7546L63.5087 15.7509C63.5087 15.7509 59.1811 17.6781 52.1182 16.5495Z" fill="#EA4335"/>
  <path d="M52.4409 22.5656L52.646 25.4955C52.646 25.4955 58.4045 26.611 63.8315 24.8444L63.6832 21.7408C63.6788 21.7408 59.4995 23.6941 52.4409 22.5656Z" fill="#EA4335"/>
  <path d="M52.8467 28.3952L53.0866 31.3251C53.0866 31.3251 58.5267 32.3408 64.2067 30.674L63.9711 27.5704C63.9668 27.5704 59.9053 29.5281 52.8467 28.3952Z" fill="#EA4335"/>
  <path d="M53.3047 34.2286L53.5708 37.1586C53.5708 37.1586 58.9673 38.2133 64.8479 36.5075L64.451 33.4039C64.451 33.4039 60.3676 35.3572 53.3047 34.2286Z" fill="#EA4335"/>
  <path d="M53.946 40.1191L54.2863 43.0447C54.2863 43.0447 59.5083 44.0126 65.8732 41.9899L65.2843 39.2119C65.2843 39.2119 61.6023 40.9351 53.946 40.1191Z" fill="#EA4335"/>
  <path d="M51.852 48.301C51.852 48.301 54.8141 47.1073 54.6745 45.7965C54.295 42.1981 51.4768 44.3511 51.4768 44.3511L51.852 48.301Z" fill="#901F1D"/>
  <path d="M54.6223 45.3625C54.6223 45.3625 53.789 42.8623 30.6763 47.1465C30.6763 47.1465 30.336 44.8373 30.2837 44.1819C41.5827 42.1982 53.7978 40.7875 54.2122 42.3762L54.6223 45.3625Z" fill="#EA4335"/>
  <path d="M29.7559 40.2147L30.6764 47.1423C30.6764 47.1423 33.4902 44.9764 33.3375 43.1967C33.1848 41.4171 29.7559 40.2147 29.7559 40.2147Z" fill="#901F1D"/>
  <path d="M3.95598 43.431L3.5459 47.1466C3.5459 47.1466 19.9447 41.4691 29.0318 41.1522C33.1457 41.009 33.3376 43.201 33.3376 43.201L32.9188 38.3655C32.9145 38.3655 28.9577 34.7107 3.95598 43.431Z" fill="#EA4335"/>
  <path d="M4.34417 7.32564L4.04315 7.68158C3.99953 7.73366 3.9908 7.81179 4.03006 7.86822L4.28309 8.25888C4.29618 8.27624 4.30054 8.2936 4.3049 8.31096C4.32672 8.42816 4.20457 8.54102 4.07805 8.49327L3.6418 8.31965C3.61126 8.30662 3.57636 8.30662 3.54146 8.31096C3.51092 8.31965 3.48038 8.33701 3.45857 8.36305L3.15756 8.71898C3.07031 8.82316 2.90453 8.77975 2.87399 8.6669C2.86963 8.64953 2.86527 8.63217 2.86963 8.61047L2.91325 8.14602C2.91762 8.07657 2.87835 8.01146 2.81292 7.98542L2.37666 7.81179C2.2327 7.75537 2.24142 7.52965 2.39411 7.49059L2.84782 7.37773C2.91762 7.36037 2.96997 7.3213 2.97433 7.25185L3.00923 6.78741C3.01359 6.70928 3.07031 6.66153 3.13138 6.64417C3.19246 6.62681 3.26662 6.64851 3.30588 6.71362L3.55455 7.10861C3.58945 7.16504 3.65925 7.17806 3.72469 7.1607L4.17839 7.04785C4.33108 7.01746 4.4445 7.20845 4.34417 7.32564Z" fill="white"/>
  <path d="M5.2907 10.7677L4.98968 11.1236C4.94606 11.1757 4.93733 11.2538 4.9766 11.3102L5.22962 11.7009C5.24271 11.7183 5.24707 11.7356 5.25144 11.753C5.27325 11.8702 5.1511 11.983 5.02458 11.9353L4.58833 11.7617C4.55779 11.7486 4.52289 11.7486 4.48799 11.753C4.45745 11.7617 4.42692 11.779 4.4051 11.8051L4.10409 12.161C4.01684 12.2652 3.85106 12.2218 3.82052 12.1089C3.81616 12.0915 3.8118 12.0742 3.81616 12.0525L3.85979 11.588C3.86415 11.5186 3.82489 11.4535 3.75945 11.4274L3.32319 11.2538C3.17923 11.1974 3.18796 10.9717 3.34065 10.9326L3.79435 10.8197C3.86415 10.8024 3.9165 10.7633 3.92086 10.6939L3.95576 10.2294C3.96013 10.1513 4.01684 10.1035 4.07791 10.0862C4.13899 10.0688 4.21315 10.0905 4.25242 10.1556L4.50108 10.5506C4.53598 10.6071 4.60578 10.6201 4.67122 10.6027L5.12492 10.4899C5.27325 10.4551 5.39104 10.6505 5.2907 10.7677Z" fill="white"/>
  <path d="M6.08464 14.2052L5.78363 14.5611C5.74 14.6132 5.73128 14.6914 5.77054 14.7478L6.02357 15.1384C6.03665 15.1558 6.04102 15.1732 6.04538 15.1905C6.06719 15.3077 5.94504 15.4206 5.81853 15.3728L5.38227 15.1992C5.35174 15.1862 5.31684 15.1862 5.28194 15.1905C5.2514 15.1992 5.22086 15.2166 5.19905 15.2426L4.89803 15.5986C4.81078 15.7027 4.64501 15.6593 4.61447 15.5465C4.61011 15.5291 4.60574 15.5117 4.61011 15.49L4.65373 15.0256C4.65809 14.9561 4.61883 14.891 4.55339 14.865L4.11714 14.6914C3.97318 14.6349 3.9819 14.4092 4.13459 14.3702L4.58829 14.2616C4.65809 14.2443 4.71044 14.2052 4.71481 14.1358L4.74971 13.6713C4.75407 13.5932 4.81078 13.5454 4.87186 13.5281C4.93293 13.5107 5.0071 13.5324 5.04636 13.5975L5.29502 13.9925C5.32992 14.049 5.39972 14.062 5.46516 14.0446L5.91887 13.9318C6.07155 13.897 6.18934 14.088 6.08464 14.2052Z" fill="white"/>
  <path d="M6.98357 17.7909L6.68256 18.1468C6.63893 18.1989 6.6302 18.277 6.66947 18.3334L6.9225 18.7241C6.93558 18.7415 6.93994 18.7588 6.94431 18.7762C6.96612 18.8934 6.84397 19.0062 6.71746 18.9585L6.2812 18.7849C6.25066 18.7718 6.21576 18.7718 6.18086 18.7762C6.14596 18.7805 6.11979 18.8022 6.09798 18.8283L5.79696 19.1842C5.70971 19.2884 5.54393 19.245 5.5134 19.1321C5.50903 19.1147 5.50467 19.0974 5.50903 19.0757L5.55266 18.6112C5.55702 18.5418 5.51776 18.4767 5.45232 18.4506L5.01607 18.277C4.8721 18.2206 4.88083 17.9949 5.03352 17.9558L5.48722 17.8429C5.55702 17.8256 5.60937 17.7865 5.61373 17.7171L5.64863 17.2526C5.653 17.1745 5.70971 17.1267 5.77078 17.1094C5.83186 17.092 5.90602 17.1137 5.94529 17.1788L6.19395 17.5738C6.22885 17.6303 6.29865 17.6433 6.36409 17.6259L6.81779 17.5131C6.96612 17.4783 7.08391 17.6737 6.98357 17.7909Z" fill="white"/>
  <path d="M7.87786 21.3763L7.57684 21.7322C7.53322 21.7843 7.52449 21.8625 7.56375 21.9189L7.81678 22.3095C7.82987 22.3269 7.83423 22.3443 7.83859 22.3616C7.8604 22.4788 7.73825 22.5917 7.61174 22.5439L7.17549 22.3703C7.14495 22.3573 7.11005 22.3573 7.07515 22.3616C7.04025 22.366 7.01407 22.3877 6.99226 22.4137L6.69125 22.7696C6.604 22.8738 6.43822 22.8304 6.40768 22.7176C6.40332 22.7002 6.39896 22.6828 6.40332 22.6611L6.44694 22.1967C6.45131 22.1272 6.41204 22.0621 6.34661 22.0361L5.91035 21.8625C5.76639 21.806 5.77511 21.5803 5.9278 21.5412L6.38151 21.4284C6.45131 21.411 6.50366 21.372 6.50802 21.3025L6.54292 20.8381C6.54728 20.7599 6.604 20.7122 6.66507 20.6948C6.72615 20.6775 6.80031 20.6992 6.83957 20.7643L7.08824 21.1593C7.12314 21.2157 7.19294 21.2287 7.25838 21.2114L7.71208 21.0985C7.8604 21.0638 7.97819 21.2591 7.87786 21.3763Z" fill="white"/>
  <path d="M9.61833 18.963L9.31732 19.3189C9.27369 19.371 9.26497 19.4491 9.30423 19.5056L9.55726 19.8962C9.57034 19.9136 9.57471 19.9309 9.57907 19.9483C9.60088 20.0655 9.47873 20.1783 9.35222 20.1306L8.91597 19.957C8.88543 19.944 8.85053 19.944 8.81563 19.9483C8.78509 19.957 8.75455 19.9743 8.73274 20.0004L8.43172 20.3563C8.34447 20.4605 8.1787 20.4171 8.14816 20.3042C8.1438 20.2869 8.13944 20.2695 8.1438 20.2478L8.18742 19.7834C8.19178 19.7139 8.15252 19.6488 8.08708 19.6227L7.65083 19.4491C7.50687 19.3927 7.51559 19.167 7.66828 19.1279L8.12199 19.0151C8.19179 18.9977 8.24413 18.9586 8.2485 18.8892L8.2834 18.4247C8.28776 18.3466 8.34447 18.2989 8.40555 18.2815C8.46662 18.2641 8.54079 18.2858 8.58005 18.3509L8.82871 18.7459C8.86361 18.8024 8.93341 18.8154 8.99885 18.798L9.45256 18.6852C9.60088 18.6505 9.71867 18.8458 9.61833 18.963Z" fill="white"/>
  <path d="M8.77214 15.3774L8.47113 15.7333C8.4275 15.7854 8.41878 15.8636 8.45804 15.92L8.71107 16.3106C8.72415 16.328 8.72852 16.3454 8.73288 16.3627C8.75469 16.4799 8.63254 16.5928 8.50603 16.545L8.06977 16.3714C8.03924 16.3584 8.00434 16.3584 7.96944 16.3627C7.9389 16.3714 7.90836 16.3888 7.88655 16.4148L7.58553 16.7707C7.49828 16.8749 7.33251 16.8315 7.30197 16.7187C7.29761 16.7013 7.29324 16.6839 7.29761 16.6622L7.34123 16.1978C7.34559 16.1283 7.30633 16.0632 7.24089 16.0372L6.80464 15.8636C6.66068 15.8071 6.6694 15.5814 6.82209 15.5423L7.27579 15.4295C7.34559 15.4121 7.39794 15.3731 7.40231 15.3036L7.43721 14.8392C7.44157 14.761 7.49828 14.7133 7.55936 14.6959C7.62043 14.6786 7.6946 14.7003 7.73386 14.7654L7.98252 15.1604C8.01742 15.2168 8.08722 15.2298 8.15266 15.2125L8.60636 15.0996C8.75469 15.0649 8.87248 15.2602 8.77214 15.3774Z" fill="white"/>
  <path d="M8.06096 11.7098L7.75994 12.0657C7.71632 12.1178 7.70759 12.1959 7.74686 12.2524L7.99988 12.643C8.01297 12.6604 8.01733 12.6778 8.0217 12.6951C8.04351 12.8123 7.92136 12.9252 7.79484 12.8774L7.35859 12.7038C7.32805 12.6908 7.29315 12.6908 7.25825 12.6951C7.22335 12.6995 7.19718 12.7212 7.17536 12.7472L6.87435 13.1031C6.7871 13.2073 6.62132 13.1639 6.59079 13.051C6.58642 13.0337 6.58206 13.0163 6.58642 12.9946L6.63005 12.5302C6.63441 12.4607 6.59515 12.3956 6.52971 12.3696L6.09346 12.1959C5.94949 12.1395 5.95822 11.9138 6.11091 11.8747L6.56461 11.7619C6.63441 11.7445 6.68676 11.7055 6.69112 11.636L6.72602 11.1716C6.73039 11.0934 6.7871 11.0457 6.84817 11.0283C6.90925 11.011 6.98341 11.0327 7.02268 11.0978L7.27134 11.4928C7.30624 11.5492 7.37604 11.5622 7.44148 11.5449L7.89518 11.432C8.04787 11.3973 8.16129 11.5883 8.06096 11.7098Z" fill="white"/>
  <path d="M7.16667 8.26754L6.86566 8.62347C6.82203 8.67556 6.81331 8.75369 6.85257 8.81012L7.1056 9.20078C7.11869 9.21814 7.12305 9.2355 7.12741 9.25286C7.14922 9.37006 7.02707 9.48292 6.90056 9.43517L6.46431 9.26154C6.43377 9.24852 6.39887 9.24852 6.36397 9.25286C6.33343 9.26154 6.30289 9.27891 6.28108 9.30495L5.98006 9.66088C5.89281 9.76506 5.72704 9.72165 5.6965 9.60879C5.69214 9.59143 5.68777 9.57407 5.69214 9.55237L5.73576 9.08792C5.74013 9.01847 5.70086 8.95336 5.63542 8.92732L5.19917 8.75369C5.05521 8.69726 5.06393 8.47155 5.21662 8.43248L5.67033 8.31963C5.74013 8.30227 5.79248 8.2632 5.79684 8.19375L5.83174 7.7293C5.8361 7.65117 5.89281 7.60343 5.95389 7.58606C6.01496 7.5687 6.08913 7.5904 6.12839 7.65551L6.37705 8.05051C6.41195 8.10694 6.48176 8.11996 6.54719 8.1026L7.0009 7.98974C7.15359 7.95502 7.26701 8.15034 7.16667 8.26754Z" fill="white"/>
  <path d="M9.21262 5.73678L8.89852 6.07969C8.85053 6.13177 8.8418 6.20556 8.8767 6.26633L9.11664 6.66567C9.12537 6.68303 9.13409 6.70039 9.13846 6.71776C9.15591 6.83495 9.02939 6.94347 8.90724 6.89138L8.47971 6.70473C8.44918 6.69171 8.41428 6.68737 8.37938 6.69605C8.34448 6.70473 8.3183 6.71776 8.29213 6.7438L7.97802 7.09105C7.88641 7.19088 7.725 7.14314 7.69446 7.02594C7.6901 7.00858 7.69009 6.98687 7.69009 6.96951L7.74681 6.50507C7.75553 6.43562 7.71627 6.37051 7.65083 6.34446L7.2233 6.15782C7.07934 6.09705 7.10115 5.87134 7.25384 5.84095L7.71191 5.74546C7.78171 5.73244 7.83406 5.69337 7.84278 5.62392L7.89077 5.15947C7.8995 5.08134 7.95621 5.0336 8.01728 5.02057C8.07836 5.00755 8.15252 5.02926 8.19179 5.09437L8.42736 5.49804C8.46226 5.55881 8.5277 5.57183 8.5975 5.55881L9.05557 5.46332C9.20826 5.42859 9.31732 5.62392 9.21262 5.73678Z" fill="white"/>
  <path d="M10.0371 9.20931L9.72298 9.55222C9.67499 9.60431 9.66627 9.6781 9.70117 9.73887L9.94111 10.1382C9.94983 10.1556 9.95856 10.1729 9.96292 10.1903C9.98037 10.3075 9.85386 10.416 9.73171 10.3639L9.30418 10.1773C9.27364 10.1643 9.23874 10.1599 9.20384 10.1686C9.1733 10.1773 9.14276 10.1903 9.11659 10.2163L8.80248 10.5636C8.71087 10.6634 8.54946 10.6157 8.51892 10.4985C8.51456 10.4811 8.51456 10.4594 8.51456 10.4421L8.57127 9.9776C8.58 9.90815 8.54073 9.84304 8.47529 9.817L8.04777 9.63035C7.9038 9.56959 7.92562 9.34387 8.0783 9.31349L8.53637 9.21799C8.60617 9.20497 8.65852 9.16591 8.66725 9.09646L8.71523 8.63201C8.72396 8.55388 8.78067 8.50613 8.84175 8.49311C8.90282 8.48009 8.97699 8.50179 9.01625 8.5669L9.25183 8.97058C9.28673 9.03135 9.35217 9.04437 9.42197 9.03135L9.88003 8.93585C10.0327 8.89679 10.1418 9.09212 10.0371 9.20931Z" fill="white"/>
  <path d="M10.7177 12.6732L10.4036 13.0162C10.3557 13.0682 10.3469 13.142 10.3818 13.2028L10.6218 13.6021C10.6305 13.6195 10.6392 13.6369 10.6436 13.6542C10.661 13.7714 10.5345 13.8799 10.4124 13.8279L9.98484 13.6412C9.9543 13.6282 9.9194 13.6238 9.8845 13.6325C9.85396 13.6412 9.82343 13.6542 9.79725 13.6803L9.48315 14.0275C9.39154 14.1274 9.23012 14.0796 9.19958 13.9624C9.19522 13.9451 9.19522 13.9234 9.19522 13.906L9.25194 13.4415C9.26066 13.3721 9.2214 13.307 9.15596 13.2809L8.72843 13.0943C8.58447 13.0335 8.60628 12.8078 8.75897 12.7774L9.21703 12.6819C9.28683 12.6689 9.33918 12.6298 9.34791 12.5604L9.3959 12.0916C9.40462 12.0135 9.46134 11.9657 9.52241 11.9527C9.58349 11.9397 9.65765 11.9614 9.69691 12.0265L9.93249 12.4302C9.96739 12.4909 10.0328 12.504 10.1026 12.4909L10.5607 12.3954C10.7134 12.3607 10.8224 12.5604 10.7177 12.6732Z" fill="white"/>
  <path d="M11.49 16.2844L11.1759 16.6273C11.1279 16.6794 11.1191 16.7532 11.154 16.8139L11.394 17.2133C11.4027 17.2306 11.4114 17.248 11.4158 17.2654C11.4333 17.3826 11.3067 17.4911 11.1846 17.439L10.7571 17.2523C10.7265 17.2393 10.6916 17.235 10.6567 17.2437C10.6218 17.2523 10.5956 17.2654 10.5695 17.2914L10.2554 17.6387C10.1638 17.7385 10.0023 17.6907 9.9718 17.5735C9.96744 17.5562 9.96744 17.5345 9.96744 17.5171L10.0242 17.0527C10.0329 16.9832 9.99361 16.9181 9.92818 16.8921L9.50065 16.7054C9.35668 16.6447 9.3785 16.4189 9.53119 16.3886L9.98925 16.2931C10.0591 16.28 10.1114 16.241 10.1201 16.1715L10.1681 15.7071C10.1768 15.629 10.2336 15.5812 10.2946 15.5682C10.3557 15.5552 10.4299 15.5769 10.4691 15.642L10.7047 16.0456C10.7396 16.1064 10.805 16.1194 10.8748 16.1064L11.3329 16.0109C11.4856 15.9762 11.5947 16.1715 11.49 16.2844Z" fill="white"/>
  <path d="M12.2619 19.9001L11.9478 20.243C11.8998 20.2951 11.8911 20.3688 11.926 20.4296L12.166 20.8289C12.1747 20.8463 12.1834 20.8637 12.1878 20.881C12.2052 20.9982 12.0787 21.1067 11.9566 21.0547L11.529 20.868C11.4985 20.855 11.4636 20.8507 11.4287 20.8593C11.3938 20.868 11.3676 20.881 11.3414 20.9071L11.0273 21.2543C10.9357 21.3542 10.7743 21.3064 10.7438 21.1892C10.7394 21.1719 10.7394 21.1502 10.7394 21.1328L10.7961 20.6683C10.8048 20.5989 10.7656 20.5338 10.7001 20.5077L10.2726 20.3211C10.1287 20.2603 10.1505 20.0346 10.3032 20.0042L10.7612 19.9044C10.831 19.8914 10.8834 19.8523 10.8921 19.7829L10.9401 19.3184C10.9488 19.2403 11.0055 19.1925 11.0666 19.1795C11.1277 19.1665 11.2018 19.1882 11.2411 19.2533L11.4767 19.657C11.5116 19.7177 11.577 19.7308 11.6468 19.7177L12.1049 19.6223C12.2576 19.5875 12.3666 19.7829 12.2619 19.9001Z" fill="white"/>
  <path d="M13.5972 4.6777L13.2787 5.01626C13.2307 5.06835 13.222 5.14214 13.2569 5.20291L13.4925 5.60659C13.5012 5.62395 13.5099 5.64131 13.5099 5.66301C13.5274 5.78021 13.3965 5.88873 13.2743 5.8323L12.8512 5.63697C12.8206 5.62395 12.7857 5.61961 12.7508 5.62395C12.7203 5.62829 12.6854 5.64565 12.6636 5.6717L12.3451 6.01026C12.2535 6.1101 12.0921 6.05801 12.0615 5.94081C12.0572 5.92345 12.0572 5.90175 12.0572 5.88439L12.1226 5.42428C12.1313 5.35483 12.0921 5.28972 12.031 5.25934L11.6078 5.06401C11.4682 4.9989 11.49 4.77753 11.6427 4.74715L12.1052 4.65599C12.175 4.64297 12.2273 4.60391 12.236 4.53446L12.2928 4.07001C12.3015 3.99188 12.3582 3.94413 12.4236 3.93111C12.4891 3.91809 12.5589 3.94413 12.5938 4.00924L12.8206 4.41726C12.8555 4.47803 12.921 4.49105 12.9908 4.47803L13.4532 4.38688C13.5972 4.36517 13.7019 4.56484 13.5972 4.6777Z" fill="white"/>
  <path d="M14.3606 8.16342L14.0421 8.50198C13.9941 8.55407 13.9854 8.62786 14.0203 8.68863L14.2559 9.09231C14.2646 9.10967 14.2733 9.12703 14.2733 9.14874C14.2908 9.26593 14.1599 9.37445 14.0377 9.31802L13.6146 9.12269C13.584 9.10967 13.5491 9.10533 13.5142 9.10967C13.4837 9.11401 13.4488 9.13137 13.427 9.15742L13.1085 9.49599C13.0169 9.59582 12.8555 9.54373 12.825 9.42654C12.8206 9.40917 12.8206 9.38747 12.8206 9.37011L12.886 8.91C12.8948 8.84055 12.8555 8.77544 12.7944 8.74506L12.3713 8.54973C12.2317 8.48462 12.2535 8.26325 12.4062 8.23287L12.8686 8.14171C12.9384 8.12869 12.9907 8.08963 12.9995 8.02018L13.0562 7.55573C13.0649 7.4776 13.1216 7.42985 13.1871 7.41683C13.2525 7.40381 13.3223 7.42985 13.3572 7.49496L13.584 7.90298C13.6189 7.96375 13.6844 7.97677 13.7542 7.96375L14.2166 7.87259C14.3606 7.85089 14.4653 8.05056 14.3606 8.16342Z" fill="white"/>
  <path d="M14.98 11.6402L14.6615 11.9788C14.6135 12.0309 14.6048 12.1047 14.6397 12.1654L14.8753 12.5691C14.884 12.5865 14.8927 12.6038 14.8927 12.6255C14.9102 12.7427 14.7793 12.8513 14.6571 12.7948L14.234 12.5995C14.2034 12.5865 14.1685 12.5821 14.1336 12.5865C14.1031 12.5908 14.0682 12.6082 14.0464 12.6342L13.7279 12.9728C13.6363 13.0726 13.4749 13.0205 13.4444 12.9033C13.44 12.886 13.44 12.8643 13.44 12.8469L13.5054 12.3868C13.5142 12.3174 13.4749 12.2523 13.4138 12.2219L12.9906 12.0265C12.851 11.9614 12.8729 11.7401 13.0255 11.7097L13.488 11.6185C13.5578 11.6055 13.6101 11.5664 13.6189 11.497L13.6756 11.0325C13.6843 10.9544 13.741 10.9067 13.8064 10.8936C13.8719 10.8806 13.9417 10.9067 13.9766 10.9718L14.2034 11.3798C14.2383 11.4406 14.3038 11.4536 14.3736 11.4406L14.836 11.3494C14.98 11.3277 15.0847 11.5274 14.98 11.6402Z" fill="white"/>
  <path d="M15.6867 15.2647L15.3683 15.6033C15.3203 15.6554 15.3116 15.7292 15.3465 15.7899L15.582 16.1936C15.5908 16.211 15.5995 16.2284 15.5995 16.2501C15.6169 16.3673 15.4861 16.4758 15.3639 16.4193L14.9408 16.224C14.9102 16.211 14.8753 16.2066 14.8404 16.211C14.8099 16.2153 14.775 16.2327 14.7532 16.2587L14.4347 16.5973C14.3431 16.6971 14.1817 16.6451 14.1511 16.5279C14.1468 16.5105 14.1468 16.4888 14.1468 16.4714L14.2122 16.0113C14.2209 15.9419 14.1817 15.8768 14.1206 15.8464L13.6974 15.651C13.5578 15.5859 13.5796 15.3646 13.7323 15.3342L14.1948 15.243C14.2646 15.23 14.3169 15.1909 14.3256 15.1215L14.3824 14.6571C14.3911 14.5789 14.4478 14.5312 14.5132 14.5181C14.5787 14.5051 14.6485 14.5312 14.6834 14.5963L14.9102 15.0043C14.9451 15.0651 15.0106 15.0781 15.0804 15.0651L15.5428 14.9739C15.6911 14.9522 15.7958 15.1519 15.6867 15.2647Z" fill="white"/>
  <path d="M16.3979 18.8892L16.0795 19.2278C16.0315 19.2799 16.0228 19.3537 16.0577 19.4145L16.2932 19.8181C16.302 19.8355 16.3107 19.8529 16.3107 19.8746C16.3281 19.9918 16.1973 20.1003 16.0751 20.0439L15.6519 19.8485C15.6214 19.8355 15.5865 19.8312 15.5516 19.8355C15.5211 19.8398 15.4862 19.8572 15.4643 19.8832L15.1459 20.2218C15.0543 20.3217 14.8929 20.2696 14.8623 20.1524C14.858 20.135 14.858 20.1133 14.858 20.0959L14.9234 19.6358C14.9321 19.5664 14.8929 19.5013 14.8318 19.4709L14.4086 19.2756C14.269 19.2105 14.2908 18.9891 14.4435 18.9587L14.9059 18.8675C14.9757 18.8545 15.0281 18.8155 15.0368 18.746L15.0935 18.2816C15.1023 18.2034 15.159 18.1557 15.2244 18.1427C15.2898 18.1296 15.3596 18.1557 15.3945 18.2208L15.6214 18.6288C15.6563 18.6896 15.7217 18.7026 15.7915 18.6896L16.254 18.5984C16.3979 18.5767 16.5026 18.7764 16.3979 18.8892Z" fill="white"/>
  <path d="M14.0422 17.5433L13.7324 17.8949C13.6845 17.947 13.6801 18.0208 13.715 18.0816L13.9637 18.4766C13.9724 18.4939 13.9811 18.5113 13.9855 18.5287C14.0073 18.6459 13.8808 18.7587 13.7543 18.7066L13.3224 18.5243C13.2918 18.5113 13.2569 18.507 13.222 18.5156C13.1871 18.5243 13.161 18.5417 13.1348 18.5677L12.825 18.9193C12.7378 19.0191 12.572 18.9757 12.5415 18.8585C12.5371 18.8412 12.5328 18.8195 12.5371 18.8021L12.5895 18.3377C12.5938 18.2682 12.5546 18.2031 12.4935 18.1771L12.0616 17.9948C11.9176 17.934 11.9351 17.7083 12.0834 17.6779L12.5415 17.5737C12.6113 17.5564 12.6636 17.5173 12.6724 17.4478L12.716 16.9834C12.7247 16.9053 12.7771 16.8575 12.8425 16.8402C12.9036 16.8271 12.9777 16.8488 13.017 16.9139L13.2569 17.3133C13.2918 17.374 13.3573 17.3827 13.4271 17.3697L13.8851 17.2655C14.0335 17.2308 14.1469 17.4261 14.0422 17.5433Z" fill="white"/>
  <path d="M13.2656 13.9449L12.9558 14.2965C12.9078 14.3486 12.9035 14.4224 12.9384 14.4832L13.1871 14.8782C13.1958 14.8955 13.2045 14.9129 13.2089 14.9303C13.2307 15.0475 13.1042 15.1603 12.9776 15.1082L12.5458 14.9259C12.5152 14.9129 12.4803 14.9086 12.4454 14.9172C12.4149 14.9259 12.3843 14.9433 12.3582 14.9693L12.0484 15.3209C11.9612 15.4208 11.7954 15.3773 11.7649 15.2602C11.7605 15.2428 11.7561 15.2211 11.7605 15.2037L11.8129 14.7393C11.8172 14.6698 11.778 14.6047 11.7169 14.5787L11.285 14.3964C11.141 14.3356 11.1585 14.1099 11.3068 14.0795L11.7649 13.9753C11.8347 13.958 11.887 13.9189 11.8957 13.8495L11.9394 13.385C11.9481 13.3069 12.0004 13.2591 12.0659 13.2418C12.127 13.2287 12.2011 13.2504 12.2404 13.3156L12.4803 13.7149C12.5152 13.7757 12.5807 13.7843 12.6505 13.7713L13.1085 13.6671C13.2569 13.6324 13.3659 13.8277 13.2656 13.9449Z" fill="white"/>
  <path d="M12.6242 10.2599L12.3145 10.6115C12.2665 10.6636 12.2621 10.7374 12.297 10.7981L12.5457 11.1931C12.5544 11.2105 12.5631 11.2278 12.5675 11.2452C12.5893 11.3624 12.4628 11.4753 12.3363 11.4232L11.9044 11.2409C11.8739 11.2278 11.839 11.2235 11.8041 11.2322C11.7735 11.2409 11.743 11.2582 11.7168 11.2843L11.4071 11.6359C11.3198 11.7357 11.154 11.6923 11.1235 11.5751C11.1191 11.5577 11.1148 11.536 11.1191 11.5187L11.1715 11.0542C11.1759 10.9848 11.1366 10.9197 11.0755 10.8936L10.6436 10.7113C10.4997 10.6505 10.5171 10.4248 10.6654 10.3944L11.1235 10.2903C11.1933 10.2729 11.2457 10.2338 11.2544 10.1644L11.298 9.69995C11.3067 9.62182 11.3591 9.57407 11.4245 9.55671C11.4856 9.54369 11.5598 9.56539 11.599 9.6305L11.839 10.0298C11.8739 10.0906 11.9393 10.0993 12.0091 10.0863L12.4672 9.98209C12.6155 9.94736 12.7289 10.1427 12.6242 10.2599Z" fill="white"/>
  <path d="M11.7954 6.80469L11.4856 7.15628C11.4376 7.20837 11.4333 7.28216 11.4682 7.34293L11.7168 7.73792C11.7256 7.75528 11.7343 7.77265 11.7387 7.79001C11.7605 7.90721 11.6339 8.02006 11.5074 7.96797L11.0755 7.78567C11.045 7.77265 11.0101 7.76831 10.9752 7.77699C10.9447 7.78567 10.9141 7.80303 10.888 7.82907L10.5782 8.18067C10.491 8.2805 10.3252 8.23709 10.2947 8.1199C10.2903 8.10253 10.2859 8.08083 10.2903 8.06347L10.3426 7.59902C10.347 7.52957 10.3077 7.46446 10.2467 7.43842L9.81477 7.25611C9.67081 7.19534 9.68826 6.96963 9.83659 6.93925L10.2947 6.83507C10.3645 6.81771 10.4168 6.77864 10.4255 6.70919L10.4692 6.24475C10.4779 6.16662 10.5302 6.11887 10.5957 6.10151C10.6567 6.08849 10.7309 6.11019 10.7702 6.1753L11.0101 6.57464C11.045 6.6354 11.1104 6.64408 11.1802 6.63106L11.6383 6.52689C11.7866 6.49216 11.8957 6.68749 11.7954 6.80469Z" fill="white"/>
  <path d="M18.2258 16.7189L17.9117 17.0661C17.8637 17.1182 17.8593 17.192 17.8942 17.2528L18.1386 17.6521C18.1473 17.6695 18.156 17.6868 18.1604 17.7042C18.1822 17.8214 18.0513 17.9299 17.9291 17.8778L17.4973 17.6912C17.4667 17.6782 17.4318 17.6738 17.3969 17.6825C17.3664 17.6912 17.3358 17.7085 17.3097 17.7302L16.9956 18.0775C16.904 18.1773 16.7425 18.1296 16.712 18.0167C16.7076 17.9994 16.7076 17.9777 16.7076 17.9603L16.7644 17.4959C16.7731 17.4264 16.7338 17.3613 16.6684 17.3353L16.2408 17.1486C16.0969 17.0878 16.1143 16.8621 16.267 16.8317L16.7251 16.7319C16.7949 16.7189 16.8472 16.6798 16.856 16.6104L16.904 16.1459C16.9127 16.0678 16.965 16.02 17.0305 16.007C17.0915 15.994 17.1657 16.0157 17.205 16.0808L17.4405 16.4845C17.4754 16.5453 17.5409 16.5583 17.6107 16.5409L18.0687 16.4411C18.2214 16.4064 18.3305 16.606 18.2258 16.7189Z" fill="white"/>
  <path d="M17.4841 13.1118L17.17 13.4591C17.122 13.5112 17.1177 13.585 17.1526 13.6457L17.3969 14.0451C17.4056 14.0624 17.4143 14.0798 17.4187 14.0972C17.4405 14.2143 17.3096 14.3229 17.1875 14.2708L16.76 14.0841C16.7294 14.0711 16.6945 14.0668 16.6596 14.0754C16.6291 14.0841 16.5985 14.1015 16.5724 14.1232L16.2583 14.4704C16.1666 14.5703 16.0052 14.5225 15.9747 14.4097C15.9703 14.3923 15.9703 14.3706 15.9703 14.3532L16.027 13.8888C16.0358 13.8194 15.9965 13.7542 15.9311 13.7282L15.5035 13.5416C15.3596 13.4808 15.377 13.2551 15.5297 13.2247L15.9878 13.1249C16.0576 13.1118 16.1099 13.0728 16.1187 13.0033L16.1667 12.5389C16.1754 12.4607 16.2277 12.413 16.2932 12.4C16.3542 12.3869 16.4284 12.4087 16.4677 12.4738L16.7032 12.8774C16.7381 12.9382 16.8036 12.9512 16.8734 12.9339L17.3314 12.834C17.4798 12.7993 17.5888 12.9946 17.4841 13.1118Z" fill="white"/>
  <path d="M16.8821 9.42238L16.568 9.76963C16.52 9.82171 16.5156 9.89551 16.5505 9.95627L16.7948 10.3556C16.8035 10.373 16.8123 10.3903 16.8166 10.4077C16.8385 10.5249 16.7076 10.6334 16.5854 10.5813L16.1579 10.3947C16.1274 10.3817 16.0925 10.3773 16.0576 10.386C16.0227 10.3947 15.9965 10.412 15.9703 10.4337L15.6562 10.781C15.5646 10.8808 15.4032 10.8331 15.3726 10.7202C15.3683 10.7029 15.3683 10.6812 15.3683 10.6638L15.425 10.1993C15.4337 10.1299 15.3945 10.0648 15.329 10.0387L14.9015 9.8521C14.7575 9.79133 14.775 9.56562 14.9277 9.53523L15.3857 9.4354C15.4555 9.42238 15.5079 9.38331 15.5166 9.31386L15.5646 8.84942C15.5733 8.77128 15.6257 8.72354 15.6911 8.71052C15.7522 8.69749 15.8263 8.7192 15.8656 8.78431L16.1012 9.18798C16.1361 9.24875 16.2015 9.26177 16.2713 9.24441L16.7294 9.14458C16.8777 9.10985 16.9868 9.30518 16.8821 9.42238Z" fill="white"/>
  <path d="M16.0925 5.95851L15.7784 6.30576C15.7304 6.35785 15.726 6.43164 15.7609 6.49241L16.0052 6.89174C16.014 6.90911 16.0227 6.92647 16.0271 6.94383C16.0489 7.06103 15.918 7.16954 15.7958 7.11745L15.3683 6.93081C15.3378 6.91779 15.3029 6.91345 15.268 6.92213C15.2374 6.93081 15.2069 6.94817 15.1807 6.96987L14.8623 7.31712C14.7707 7.41696 14.6092 7.36921 14.5787 7.25635C14.5743 7.23899 14.5743 7.21729 14.5743 7.19993L14.6311 6.73548C14.6398 6.66603 14.6005 6.60092 14.5351 6.57488L14.1075 6.38823C13.9636 6.32746 13.981 6.10175 14.1337 6.07137L14.5918 5.97153C14.6616 5.95851 14.7139 5.91944 14.7227 5.84999L14.7707 5.38555C14.7794 5.30742 14.8317 5.25967 14.8972 5.24665C14.9582 5.23363 15.0324 5.25533 15.0717 5.32044L15.3072 5.72412C15.3421 5.78489 15.4076 5.79791 15.4774 5.78054L15.9354 5.68071C16.0838 5.64599 16.1928 5.84131 16.0925 5.95851Z" fill="white"/>
  <path d="M17.9814 3.62736L17.6586 3.96593C17.6106 4.01801 17.6019 4.09181 17.6324 4.15257L17.8636 4.56059C17.8724 4.57796 17.8811 4.59532 17.8811 4.61702C17.8985 4.73422 17.7677 4.84273 17.6455 4.78196L17.2223 4.5823C17.1918 4.56927 17.1569 4.56493 17.122 4.56927C17.0871 4.57361 17.0566 4.59098 17.0348 4.61702L16.7119 4.95559C16.6203 5.05108 16.4545 4.999 16.4284 4.88614C16.424 4.86878 16.424 4.84707 16.424 4.82971L16.4938 4.36961C16.5025 4.30016 16.4676 4.23505 16.4022 4.20466L15.979 4.00499C15.8394 3.93988 15.8612 3.71417 16.0183 3.68813L16.4807 3.60132C16.5505 3.58829 16.6072 3.54923 16.6159 3.48412L16.677 3.01967C16.6857 2.94154 16.7425 2.89379 16.8079 2.88511C16.8733 2.87643 16.9431 2.89813 16.978 2.96758L17.2005 3.3756C17.2354 3.43637 17.3009 3.44939 17.3663 3.44071L17.8287 3.3539C17.9901 3.31483 18.0905 3.5145 17.9814 3.62736Z" fill="white"/>
  <path d="M18.7143 7.11719L18.3915 7.45575C18.3435 7.50784 18.3348 7.58163 18.3653 7.6424L18.5965 8.05042C18.6053 8.06778 18.614 8.08514 18.614 8.10685C18.6314 8.22404 18.5006 8.33256 18.3784 8.27179L17.9553 8.07212C17.9247 8.0591 17.8898 8.05476 17.8549 8.0591C17.82 8.06344 17.7895 8.0808 17.7677 8.10685L17.4448 8.44541C17.3532 8.54091 17.1874 8.48882 17.1613 8.37596C17.1569 8.3586 17.1569 8.3369 17.1569 8.31954L17.2267 7.85943C17.2354 7.78998 17.2005 7.72487 17.1351 7.69449L16.7119 7.49482C16.5723 7.42971 16.5941 7.204 16.7512 7.17795L17.2136 7.09114C17.2834 7.07812 17.3401 7.03905 17.3489 6.97394L17.4099 6.5095C17.4187 6.43137 17.4754 6.38362 17.5408 6.37494C17.6063 6.36192 17.6761 6.38796 17.711 6.45741L17.9334 6.86543C17.9683 6.9262 18.0338 6.93922 18.0992 6.93054L18.5616 6.84373C18.7187 6.80466 18.8234 7.00433 18.7143 7.11719Z" fill="white"/>
  <path d="M19.3032 10.5984L18.9804 10.937C18.9324 10.989 18.9237 11.0628 18.9542 11.1236L19.1854 11.5316C19.1941 11.549 19.2029 11.5663 19.2029 11.588C19.2203 11.7052 19.0894 11.8138 18.9673 11.753L18.5441 11.5533C18.5136 11.5403 18.4787 11.536 18.4438 11.5403C18.4132 11.5446 18.3783 11.562 18.3565 11.588L18.0337 11.9266C17.9421 12.0221 17.7763 11.97 17.7501 11.8572C17.7458 11.8398 17.7458 11.8181 17.7458 11.8007L17.8156 11.3406C17.8243 11.2712 17.7894 11.2061 17.724 11.1757L17.3008 10.976C17.1612 10.9109 17.183 10.6852 17.3401 10.6592L17.8025 10.5723C17.8723 10.5593 17.929 10.5203 17.9377 10.4551L17.9988 9.9907C18.0075 9.91257 18.0642 9.86482 18.1297 9.85614C18.1951 9.84312 18.2649 9.86916 18.2998 9.93861L18.5223 10.3466C18.5572 10.4074 18.6226 10.4204 18.6881 10.4117L19.1505 10.3249C19.3076 10.2859 19.4079 10.4855 19.3032 10.5984Z" fill="white"/>
  <path d="M19.9751 14.2314L19.6523 14.5699C19.6043 14.622 19.5955 14.6958 19.6261 14.7566L19.8573 15.1646C19.866 15.182 19.8747 15.1993 19.8747 15.221C19.8922 15.3382 19.7613 15.4468 19.6392 15.386L19.216 15.1863C19.1855 15.1733 19.1506 15.169 19.1157 15.1733C19.0808 15.1776 19.0502 15.195 19.0284 15.221L18.7056 15.5596C18.614 15.6551 18.4482 15.603 18.422 15.4902C18.4177 15.4728 18.4177 15.4511 18.4177 15.4337L18.4875 14.9736C18.4962 14.9042 18.4613 14.8391 18.3958 14.8087L17.9727 14.609C17.8331 14.5439 17.8549 14.3182 18.0119 14.2921L18.4744 14.2053C18.5442 14.1923 18.6009 14.1532 18.6096 14.0881L18.675 13.615C18.6838 13.5369 18.7405 13.4891 18.8059 13.4805C18.8714 13.4718 18.9412 13.4935 18.9761 13.5629L19.1985 13.9709C19.2334 14.0317 19.2989 14.0447 19.3643 14.0361L19.8268 13.9492C19.9794 13.9189 20.0841 14.1185 19.9751 14.2314Z" fill="white"/>
  <path d="M20.6513 17.8601L20.3285 18.1987C20.2805 18.2508 20.2718 18.3245 20.3023 18.3853L20.5336 18.7933C20.5423 18.8107 20.551 18.8281 20.551 18.8498C20.5685 18.967 20.4376 19.0755 20.3154 19.0147L19.8923 18.815C19.8617 18.802 19.8268 18.7977 19.7919 18.802C19.7614 18.8064 19.7265 18.8237 19.7047 18.8498L19.3818 19.1883C19.2902 19.2838 19.1245 19.2317 19.0983 19.1189C19.0939 19.1015 19.0939 19.0798 19.0939 19.0625L19.1637 18.6023C19.1724 18.5329 19.1375 18.4678 19.0721 18.4374L18.6489 18.2377C18.5093 18.1726 18.5312 17.9469 18.6882 17.9209L19.1506 17.8341C19.2204 17.821 19.2771 17.782 19.2859 17.7169L19.3469 17.2524C19.3557 17.1743 19.4124 17.1265 19.4778 17.1179C19.5433 17.1048 19.6131 17.1309 19.648 17.2003L19.8704 17.6083C19.9053 17.6691 19.9708 17.6821 20.0362 17.6735L20.4987 17.5866C20.6557 17.5519 20.7604 17.7516 20.6513 17.8601Z" fill="white"/>
  <path d="M22.4572 15.7247L22.1387 16.0676C22.0907 16.1197 22.082 16.1935 22.1169 16.2542L22.3525 16.6579C22.3612 16.6753 22.3699 16.6927 22.3699 16.7144C22.3874 16.8316 22.2565 16.9401 22.1343 16.8836L21.7068 16.6883C21.6763 16.6753 21.6414 16.6709 21.6065 16.6753C21.5759 16.6796 21.5454 16.697 21.5192 16.723L21.2008 17.0659C21.1091 17.1658 20.9477 17.1137 20.9172 17.0008C20.9128 16.9835 20.9128 16.9618 20.9128 16.9444L20.9739 16.4843C20.9826 16.4149 20.9434 16.3497 20.8823 16.3194L20.4548 16.124C20.3152 16.0589 20.3326 15.8376 20.4897 15.8072L20.9477 15.716C21.0175 15.703 21.0699 15.6639 21.0786 15.5945L21.1353 15.13C21.144 15.0519 21.2008 15.0042 21.2618 14.9911C21.3229 14.9781 21.3971 14.9998 21.432 15.0693L21.6632 15.4773C21.6981 15.538 21.7635 15.5511 21.8333 15.538L22.2914 15.4469C22.4615 15.4122 22.5662 15.6118 22.4572 15.7247Z" fill="white"/>
  <path d="M21.7811 12.1045L21.4627 12.4474C21.4147 12.4995 21.406 12.5733 21.4409 12.6341L21.6764 13.0378C21.6852 13.0551 21.6939 13.0725 21.6939 13.0942C21.7113 13.2114 21.5805 13.3199 21.4583 13.2635L21.0308 13.0681C21.0003 13.0551 20.9654 13.0508 20.9305 13.0551C20.8999 13.0595 20.8694 13.0768 20.8432 13.1029L20.5247 13.4458C20.4331 13.5456 20.2717 13.4935 20.2412 13.3807C20.2368 13.3633 20.2368 13.3416 20.2368 13.3242L20.2979 12.8641C20.3066 12.7947 20.2673 12.7296 20.2063 12.6992L19.7787 12.5039C19.6391 12.4387 19.6566 12.2174 19.8136 12.187L20.2804 12.0915C20.3502 12.0785 20.4026 12.0394 20.4113 11.97L20.468 11.5055C20.4767 11.4274 20.5335 11.3796 20.5945 11.3666C20.66 11.3536 20.7298 11.3753 20.7647 11.4447L20.9959 11.8528C21.0308 11.9135 21.0962 11.9266 21.166 11.9135L21.6241 11.8224C21.7811 11.792 21.8858 11.9917 21.7811 12.1045Z" fill="white"/>
  <path d="M21.2445 8.40633L20.9261 8.74924C20.8781 8.80133 20.8693 8.87512 20.9042 8.93589L21.1398 9.33957C21.1486 9.35693 21.1573 9.37429 21.1573 9.396C21.1747 9.51319 21.0438 9.62171 20.9217 9.56528L20.4942 9.36995C20.4636 9.35693 20.4287 9.35259 20.3938 9.35693C20.3633 9.36127 20.3328 9.37863 20.3066 9.40468L19.9881 9.74759C19.8965 9.84742 19.7351 9.79533 19.7045 9.68248C19.7002 9.66511 19.7002 9.64341 19.7002 9.62605L19.7613 9.16594C19.77 9.09649 19.7307 9.03138 19.6696 9.001L19.2421 8.80567C19.1025 8.74056 19.12 8.51919 19.277 8.48881L19.7351 8.39765C19.8049 8.38463 19.8572 8.34557 19.866 8.27612L19.9227 7.81167C19.9314 7.73354 19.9881 7.68579 20.0492 7.67277C20.1146 7.65975 20.1844 7.68145 20.2193 7.7509L20.4505 8.15892C20.4854 8.21969 20.5509 8.23271 20.6207 8.21969L21.0788 8.12853C21.2445 8.09381 21.3492 8.29348 21.2445 8.40633Z" fill="white"/>
  <path d="M20.5116 4.92513L20.1931 5.26804C20.1452 5.32013 20.1364 5.39392 20.1713 5.45469L20.4069 5.85837C20.4156 5.87573 20.4244 5.89309 20.4244 5.91479C20.4418 6.03199 20.3109 6.14051 20.1888 6.08408L19.7613 5.88875C19.7307 5.87573 19.6958 5.87139 19.6609 5.87573C19.6304 5.88007 19.5998 5.89743 19.5737 5.92348L19.2552 6.26638C19.1636 6.36622 19.0022 6.31413 18.9716 6.20128C18.9673 6.18391 18.9673 6.16221 18.9673 6.14485L19.0284 5.68474C19.0371 5.61529 18.9978 5.55018 18.9367 5.5198L18.5092 5.32447C18.3696 5.25936 18.3871 5.03799 18.5441 5.00761L19.0022 4.91645C19.072 4.90343 19.1243 4.86436 19.1331 4.79491L19.1898 4.33047C19.1985 4.25234 19.2552 4.20459 19.3163 4.19157C19.3817 4.17855 19.4515 4.20025 19.4864 4.2697L19.7176 4.67772C19.7525 4.73849 19.818 4.75151 19.8878 4.73849L20.3458 4.64733C20.5116 4.61695 20.6163 4.81228 20.5116 4.92513Z" fill="white"/>
  <path d="M22.7062 3.05452L22.3746 3.38441C22.3266 3.43216 22.3136 3.50595 22.3441 3.57106L22.5666 3.98341C22.5753 4.00078 22.5797 4.01814 22.584 4.03984C22.5971 4.15704 22.4619 4.26121 22.3441 4.20045L21.9253 3.9921C21.8947 3.97473 21.8598 3.97039 21.8249 3.97907C21.79 3.98776 21.7595 4.00078 21.7377 4.02248L21.4061 4.35237C21.3102 4.44786 21.1488 4.39143 21.1269 4.27424C21.1226 4.25688 21.1226 4.23517 21.1269 4.21781L21.2055 3.7577C21.2142 3.68825 21.1793 3.62314 21.1182 3.59276L20.6994 3.38441C20.5598 3.31496 20.5903 3.09359 20.743 3.06754L21.2055 2.99375C21.2753 2.98073 21.332 2.94601 21.3407 2.87656L21.4149 2.41645C21.428 2.33832 21.4847 2.29491 21.5501 2.28189C21.6155 2.26887 21.6853 2.29925 21.7202 2.36436L21.934 2.78106C21.9645 2.84183 22.03 2.85919 22.0998 2.84617L22.5622 2.77238C22.7193 2.742 22.8196 2.94601 22.7062 3.05452Z" fill="white"/>
  <path d="M23.3605 6.56186L23.029 6.89175C22.981 6.9395 22.9679 7.01329 22.9984 7.0784L23.2209 7.49076C23.2296 7.50812 23.234 7.52548 23.2384 7.54718C23.2514 7.66438 23.1162 7.76856 22.9984 7.70779L22.5796 7.49944C22.5491 7.48207 22.5142 7.47773 22.4793 7.48641C22.4444 7.4951 22.4138 7.50812 22.392 7.52982L22.0605 7.85971C21.9645 7.9552 21.8031 7.89877 21.7813 7.78158C21.7769 7.76421 21.7769 7.74251 21.7813 7.72515L21.8598 7.26504C21.8685 7.19559 21.8336 7.13048 21.7725 7.1001L21.3581 6.88307C21.2185 6.81362 21.249 6.59225 21.4017 6.5662L21.8642 6.49241C21.934 6.47939 21.9907 6.44467 21.9994 6.37522L22.0736 5.91511C22.0866 5.83698 22.1434 5.79357 22.2088 5.78055C22.2742 5.77187 22.344 5.79791 22.3789 5.86302L22.5927 6.27972C22.6232 6.34049 22.6887 6.35785 22.7585 6.34483L23.2209 6.27104C23.3736 6.24934 23.4696 6.44901 23.3605 6.56186Z" fill="white"/>
  <path d="M23.871 10.0562L23.5394 10.3861C23.4914 10.4339 23.4783 10.5077 23.5089 10.5728L23.7314 10.9851C23.7401 11.0025 23.7445 11.0198 23.7488 11.0415C23.7619 11.1587 23.6267 11.2629 23.5089 11.2021L23.0901 10.9938C23.0595 10.9764 23.0246 10.9721 22.9897 10.9808C22.9548 10.9895 22.9243 11.0025 22.9025 11.0242L22.5709 11.3541C22.475 11.4496 22.3135 11.3931 22.2917 11.2759C22.2874 11.2586 22.2874 11.2369 22.2917 11.2195L22.3703 10.7594C22.379 10.69 22.3441 10.6248 22.283 10.5945L21.8642 10.3861C21.7246 10.3167 21.7551 10.0953 21.9078 10.0693L22.3703 9.99546C22.4401 9.98244 22.4968 9.94771 22.5055 9.87826L22.5797 9.41816C22.5927 9.34003 22.6495 9.29662 22.7149 9.2836C22.7803 9.27058 22.8501 9.30096 22.885 9.36607L23.0988 9.78277C23.1293 9.84354 23.1948 9.8609 23.2646 9.84788L23.727 9.77409C23.8797 9.7437 23.98 9.94337 23.871 10.0562Z" fill="white"/>
  <path d="M24.4598 13.7022L24.1283 14.0321C24.0803 14.0799 24.0672 14.1536 24.0977 14.2188L24.3202 14.6311C24.329 14.6485 24.3333 14.6702 24.3377 14.6875C24.3508 14.8047 24.2155 14.9089 24.0977 14.8481L23.6789 14.6398C23.6484 14.6224 23.6135 14.6181 23.5786 14.6268C23.5437 14.6355 23.5132 14.6485 23.4914 14.6702L23.1598 15.0001C23.0638 15.0956 22.9024 15.0391 22.8806 14.9219C22.8762 14.9046 22.8762 14.8829 22.8806 14.8655L22.9591 14.4054C22.9678 14.336 22.933 14.2708 22.8719 14.2405L22.4531 14.0321C22.3135 13.9627 22.344 13.7413 22.4967 13.7152L22.9591 13.6415C23.0289 13.6284 23.0856 13.5937 23.0944 13.5243L23.1685 13.0642C23.1816 12.986 23.2383 12.9426 23.3038 12.9296C23.3692 12.9209 23.439 12.947 23.4739 13.0121L23.6877 13.4288C23.7182 13.4895 23.7836 13.5069 23.8534 13.4939L24.3159 13.4201C24.4729 13.3897 24.5733 13.5894 24.4598 13.7022Z" fill="white"/>
  <path d="M25.0533 17.3482L24.7218 17.6781C24.6738 17.7259 24.6607 17.7996 24.6913 17.8648L24.9137 18.2771C24.9225 18.2945 24.9268 18.3118 24.9312 18.3335C24.9443 18.4507 24.809 18.5549 24.6913 18.4941L24.2725 18.2858C24.2419 18.2684 24.207 18.2641 24.1721 18.2728C24.1372 18.2815 24.1067 18.2945 24.0849 18.3162L23.7533 18.6461C23.6573 18.7416 23.4959 18.6851 23.4741 18.5679C23.4697 18.5506 23.4697 18.5289 23.4741 18.5115L23.5526 18.0514C23.5614 17.982 23.5265 17.9168 23.4654 17.8865L23.0466 17.6781C22.907 17.6087 22.9375 17.3873 23.0902 17.3612L23.5526 17.2875C23.6224 17.2744 23.6791 17.2397 23.6879 17.1703L23.762 16.7102C23.7751 16.632 23.8318 16.5886 23.8973 16.5756C23.9627 16.5669 24.0325 16.593 24.0674 16.6581L24.2812 17.0748C24.3117 17.1355 24.3772 17.1529 24.447 17.1399L24.9094 17.0661C25.0664 17.0357 25.1624 17.2354 25.0533 17.3482Z" fill="white"/>
  <path d="M27.4437 2.78963L27.1122 3.11952C27.0642 3.16726 27.0511 3.24105 27.0816 3.30616L27.3041 3.71852C27.3128 3.73588 27.3172 3.75325 27.3216 3.77495C27.3347 3.89215 27.1994 3.99632 27.0816 3.93555L26.6628 3.7272C26.6323 3.70984 26.5974 3.7055 26.5625 3.71418C26.5276 3.72286 26.4971 3.73588 26.4752 3.75759L26.1437 4.08747C26.0477 4.18297 25.8863 4.12654 25.8645 4.00934C25.8601 3.99198 25.8601 3.97028 25.8645 3.95291L25.943 3.49281C25.9517 3.42336 25.9168 3.35825 25.8558 3.32787L25.437 3.11952C25.2974 3.05007 25.3279 2.82869 25.4806 2.80265L25.943 2.72886C26.0128 2.71584 26.0695 2.68111 26.0783 2.61166L26.1524 2.15156C26.1655 2.07343 26.2222 2.03002 26.2877 2.017C26.3531 2.00398 26.4229 2.03436 26.4578 2.09947L26.6716 2.51617C26.7021 2.57694 26.7675 2.5943 26.8373 2.58128L27.2998 2.50749C27.4568 2.4771 27.5528 2.68111 27.4437 2.78963Z" fill="white"/>
  <path d="M28.098 6.29697L27.7665 6.62685C27.7185 6.6746 27.7054 6.74839 27.7359 6.8135L27.9584 7.22586C27.9671 7.24322 27.9715 7.26058 27.9759 7.28229C27.989 7.39948 27.8537 7.50366 27.7359 7.44289L27.3171 7.23454C27.2866 7.21718 27.2517 7.21284 27.2168 7.22152C27.1819 7.2302 27.1514 7.24322 27.1295 7.26493L26.798 7.59481C26.702 7.6903 26.5406 7.63388 26.5188 7.51668C26.5144 7.49932 26.5144 7.47762 26.5188 7.46025L26.5973 7.00015C26.606 6.9307 26.5711 6.86559 26.5101 6.8352L26.0913 6.61817C25.9517 6.54872 25.9822 6.32735 26.1349 6.30131L26.5973 6.22752C26.6671 6.2145 26.7238 6.17977 26.7326 6.11032L26.8067 5.65022C26.8198 5.57209 26.8765 5.52868 26.942 5.51566C27.0074 5.50698 27.0772 5.53302 27.1121 5.59813L27.3259 6.01483C27.3564 6.0756 27.4218 6.09296 27.4916 6.07994L27.9541 6.00615C28.1067 5.98444 28.2071 6.18411 28.098 6.29697Z" fill="white"/>
  <path d="M28.6041 9.79122L28.2726 10.1211C28.2246 10.1688 28.2115 10.2426 28.242 10.3077L28.4645 10.7201C28.4732 10.7375 28.4776 10.7548 28.482 10.7765C28.4951 10.8937 28.3598 10.9979 28.242 10.9371L27.8232 10.7288C27.7927 10.7114 27.7578 10.7071 27.7229 10.7158C27.688 10.7244 27.6575 10.7375 27.6356 10.7592L27.3041 11.0891C27.2081 11.1846 27.0467 11.1281 27.0249 11.0109C27.0205 10.9936 27.0205 10.9719 27.0249 10.9545L27.1034 10.4944C27.1121 10.4249 27.0772 10.3598 27.0162 10.3295L26.5974 10.1211C26.4578 10.0517 26.4883 9.83028 26.641 9.80424L27.1034 9.73045C27.1732 9.71743 27.2299 9.6827 27.2386 9.61325L27.3128 9.15314C27.3259 9.07501 27.3826 9.03161 27.4481 9.01858C27.5135 9.00556 27.5833 9.03595 27.6182 9.10106L27.832 9.51776C27.8625 9.57853 27.9279 9.59589 27.9977 9.58287L28.4602 9.50908C28.6172 9.47869 28.7132 9.6827 28.6041 9.79122Z" fill="white"/>
  <path d="M29.1974 13.4371L28.8658 13.767C28.8179 13.8147 28.8048 13.8885 28.8353 13.9536L29.0578 14.366C29.0665 14.3833 29.0709 14.4051 29.0752 14.4224C29.0883 14.5396 28.9531 14.6438 28.8353 14.583L28.4165 14.3747C28.386 14.3573 28.3511 14.353 28.3162 14.3616C28.2813 14.366 28.2507 14.3833 28.2289 14.4051L27.8974 14.7349C27.8014 14.8304 27.64 14.774 27.6182 14.6568C27.6138 14.6394 27.6138 14.6177 27.6182 14.6004L27.6967 14.1403C27.7054 14.0708 27.6705 14.0057 27.6094 13.9753L27.1906 13.767C27.051 13.6975 27.0816 13.4762 27.2342 13.4501L27.6967 13.3763C27.7665 13.3633 27.8232 13.3286 27.8319 13.2591L27.9061 12.799C27.9192 12.7209 27.9759 12.6775 28.0413 12.6645C28.1068 12.6558 28.1766 12.6818 28.2115 12.7469L28.4252 13.1636C28.4558 13.2244 28.5212 13.2418 28.591 13.2287L29.0534 13.155C29.2105 13.1246 29.3065 13.3242 29.1974 13.4371Z" fill="white"/>
  <path d="M29.7907 17.0833L29.4591 17.4132C29.4111 17.461 29.398 17.5348 29.4286 17.5999L29.6511 18.0122C29.6598 18.0296 29.6641 18.047 29.6685 18.0687C29.6816 18.1859 29.5464 18.29 29.4286 18.2293L29.0098 18.0209C28.9792 18.0035 28.9443 17.9992 28.9094 18.0079C28.8745 18.0166 28.844 18.0296 28.8222 18.0513L28.4906 18.3812C28.3946 18.4767 28.2332 18.4202 28.2114 18.303C28.2071 18.2857 28.2071 18.264 28.2114 18.2466L28.2899 17.7865C28.2987 17.7171 28.2638 17.652 28.2027 17.6216L27.7839 17.4132C27.6443 17.3438 27.6748 17.1224 27.8275 17.0964L28.2899 17.0226C28.3597 17.0095 28.4165 16.9748 28.4252 16.9054L28.4993 16.4453C28.5124 16.3671 28.5691 16.3237 28.6346 16.3107C28.7 16.302 28.7698 16.3281 28.8047 16.3932L29.0185 16.8099C29.049 16.8706 29.1145 16.888 29.1843 16.875L29.6467 16.8012C29.7994 16.7708 29.8997 16.9705 29.7907 17.0833Z" fill="white"/>
  <path d="M27.2039 15.3427L26.8767 15.6769C26.8287 15.7246 26.8157 15.8028 26.8506 15.8635L27.0774 16.2716C27.0861 16.2889 27.0949 16.3063 27.0949 16.328C27.1079 16.4452 26.9771 16.5494 26.8549 16.4929L26.4361 16.2889C26.4056 16.2716 26.3707 16.2672 26.3358 16.2759C26.3009 16.2802 26.2703 16.2976 26.2485 16.3236L25.9213 16.6579C25.8254 16.7534 25.6639 16.7013 25.6421 16.5841C25.6378 16.5667 25.6378 16.545 25.6421 16.5277L25.7163 16.0675C25.725 15.9981 25.6901 15.933 25.629 15.9026L25.2102 15.6986C25.0706 15.6291 25.0968 15.4078 25.2495 15.3817L25.7119 15.3036C25.7817 15.2906 25.8384 15.2559 25.8472 15.1864L25.9126 14.7263C25.9257 14.6482 25.9824 14.6048 26.0435 14.5917C26.1089 14.5787 26.1787 14.6048 26.2136 14.6742L26.4317 15.0866C26.4623 15.1473 26.5277 15.1647 26.5975 15.1517L27.06 15.0735C27.2126 15.0345 27.313 15.2341 27.2039 15.3427Z" fill="white"/>
  <path d="M26.6238 11.7095L26.2967 12.0437C26.2487 12.0915 26.2356 12.1696 26.2705 12.2304L26.4973 12.6384C26.5061 12.6557 26.5148 12.6731 26.5148 12.6948C26.5279 12.812 26.397 12.9162 26.2748 12.8597L25.856 12.6557C25.8255 12.6384 25.7906 12.634 25.7557 12.6427C25.7208 12.6471 25.6903 12.6644 25.6684 12.6905L25.3413 13.0247C25.2453 13.1202 25.0839 13.0681 25.0621 12.9509C25.0577 12.9335 25.0577 12.9118 25.0621 12.8945L25.1362 12.4344C25.1449 12.3649 25.11 12.2998 25.049 12.2694L24.6302 12.0654C24.4906 11.996 24.5167 11.7746 24.6694 11.7486L25.1319 11.6704C25.2017 11.6574 25.2584 11.6227 25.2671 11.5532L25.3325 11.0931C25.3456 11.015 25.4023 10.9716 25.4634 10.9586C25.5288 10.9455 25.5986 10.9716 25.6335 11.041L25.8517 11.4534C25.8822 11.5142 25.9476 11.5315 26.0174 11.5185L26.4799 11.4404C26.6326 11.397 26.7329 11.5966 26.6238 11.7095Z" fill="white"/>
  <path d="M26.1829 7.99843L25.8557 8.33265C25.8077 8.3804 25.7947 8.45853 25.8296 8.5193L26.0564 8.92732C26.0651 8.94468 26.0739 8.96204 26.0739 8.98375C26.0869 9.10094 25.9561 9.20512 25.8339 9.14869L25.4151 8.94468C25.3846 8.92732 25.3497 8.92298 25.3148 8.93166C25.2799 8.936 25.2493 8.95336 25.2275 8.97941L24.9003 9.31363C24.8044 9.40913 24.6429 9.35704 24.6211 9.23984C24.6168 9.22248 24.6168 9.20078 24.6211 9.18341L24.6953 8.72331C24.704 8.65386 24.6691 8.58875 24.608 8.55837L24.1892 8.35436C24.0496 8.28491 24.0758 8.06353 24.2285 8.03749L24.6909 7.95936C24.7607 7.94634 24.8174 7.91161 24.8262 7.84216L24.8916 7.38206C24.9047 7.30393 24.9614 7.26052 25.0225 7.2475C25.0879 7.23448 25.1577 7.26052 25.1926 7.32997L25.4108 7.74233C25.4413 7.8031 25.5067 7.82046 25.5765 7.80744L26.039 7.72931C26.1916 7.6859 26.292 7.88557 26.1829 7.99843Z" fill="white"/>
  <path d="M25.5418 4.49977L25.2146 4.834C25.1666 4.88174 25.1535 4.95988 25.1884 5.02064L25.4153 5.42866C25.424 5.44603 25.4327 5.46339 25.4327 5.48509C25.4458 5.60229 25.315 5.70646 25.1928 5.65003L24.774 5.44602C24.7435 5.42866 24.7086 5.42432 24.6737 5.433C24.6388 5.43734 24.6082 5.45471 24.5864 5.48075L24.2592 5.81498C24.1632 5.91047 24.0018 5.85838 23.98 5.74119C23.9757 5.72382 23.9757 5.70212 23.98 5.68476L24.0542 5.22465C24.0629 5.1552 24.028 5.09009 23.9669 5.05971L23.5481 4.8557C23.4085 4.78625 23.4347 4.56488 23.5874 4.53883L24.0498 4.4607C24.1196 4.44768 24.1763 4.41296 24.1851 4.34351L24.2505 3.8834C24.2636 3.80527 24.3203 3.76186 24.3814 3.74884C24.4468 3.73582 24.5166 3.76186 24.5515 3.83131L24.7696 4.24367C24.8002 4.30444 24.8656 4.3218 24.9354 4.30878L25.3978 4.23065C25.5505 4.18724 25.6509 4.39125 25.5418 4.49977Z" fill="white"/>
  </svg>
  
    )
    

export const featureList = [
  {
    item: (
      <>
        Family Owned
        <span className="hidden md:inline"> & Operated</span>
      </>
    ),
    icon: Users2,
  },
  {
    item: (
      <>
        Licensed & Insured 
      </>
    ),
    icon: BadgeCheck,
  },
  {
    item: (
      <>
        Free Estimates
      </>
    ),
    icon: ListOrdered,
  },
]


function Navbar({ headerTransparent = false, shadow = true }) {
  const companyname = "Roach Roofing";
  const description = "desc";
  const slogan = "#1 Renovation Company Michigan";
  const subtagline = "20+ years of serving communities";
  const phonenumber = "(269) 420-6623";
  const mainnavigationstyle = "inline";

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [contactFormOpen, setContactFormOpen] = useState(false);

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add("menu-expanded");
    }

    return function cleanup() {
      document.body.classList.remove("menu-expanded");
    };
  }, [mobileMenuOpen]);

  const MenuItem = ({ item, source, headerTransparent }) => {
    let itemId = item.id;
    let title = item.name;
    let slug = item.url;

    if (item.submenu && item.submenu.length > 0) {
      return (
        <MenuItemDropdown item={item} label={title} type={1} source={source} headerTransparent={headerTransparent} />
      );
    }
    return (
      <Link
        to={`${slug && slug.length < 2 ? "/" : slug}`}
        activeClassName=" text-brand-600"
        className={`${
          source && source == "mobilemenu"
            ? "text-neutral-200 hover:text-white  block py-4 px-8 border-b border-white/20"
            : `p-3 py-7 border-transparent     ${
                headerTransparent
                  ? "text-neutral-600 hover:text-neutral-900 "
                  : "text-neutral-200 hover:text-white    "
              }`
        }  font-semibold text-[15px] font-display text-base  transition-colors duration-150 ease-in md:border-b-[3px] md:border-transparent
        

        relative after:bg-brand-600 after:absolute after:h-[3px] after:w-0 after:bottom-[-3px] after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointer
        
        
        `}
 
      >
        {item.name}
      </Link>
    );
  };

  

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQueryInNav {
          site {
            siteMetadata {
              title
              description
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
          allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
            edges {
              node {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow
                    }
                  }
                }
                campaign
              }
            }
          }
        }
      `}
      render={(data) => {
        const items = data.allContentfulNavigation.edges[0].node.menuItems;
        return (
          <>
         
         <div className={` items-centerr ${" bg-222 block text-white"}  font-display  block relative z-20`}>
                <div className="flex justify-center md:justify-between items-centerr  font-display mx-auto py-2 text-xs md:text-sm max-w-7xl px-4 md:px-6">
                  <span className="block text-center md:text-left font-medium">#1 Roofing Experts Serving Michigan </span>
                  <span className=" hidden md:flex items-center text-center md:text-left font-medium">
                    
                    <USAICON/>{subtagline}</span>
                </div>
              </div>
            <div className={`sticky top-0 z-[20]   border-gray-800 ${headerTransparent ? "header--transparent-main" : ""}`}>
            
              <div
                className={`${headerTransparent ? " bg-white/80 backdrop-blur-lg -mb-[95px]  " : "bg-[#2b2b2b]"} ${
                  !shadow ? "" : "md:shadow-sm nav-shadow"
                } relative z-20 `}
              >
                <div className="flex justify-between items-center px-4 md:px-6  md:space-x-10 mx-auto max-w-7xl max-h-[94px] ">
                <div className="flex items-center logo-navbar-dark py-5 relative"> 
                
                  <Link to="/" className="block  -mx-2 -my-1 w-40 md:w-48 shrink-0" title={companyname}>
                    <Logo classes={"transition text-white"} singleColor={true} />
                  </Link>
                  </div>

                  {mainnavigationstyle == "inline" ? (
                    <div className="items-center font-display md:space-x-4 hidden lg:flex">
                      {items.map((item, i) => {
                        return (
                          <MenuItem key={item.id} item={item} type={item.type} headerTransparent={headerTransparent} />
                        );
                      })}
                    </div>
                  ) : null}

                  <div className="flex-1"/>

                  {mainnavigationstyle == "stacked" ? (
                    <div className="hidden lg:block font-display text-center">
                      <span className="font-semibold block">{slogan}</span>
                      <span className="text-sm text-neutral-800 block">{subtagline}</span>
                    </div>
                  ) : null}

                 <div className="flex space-x-1 md:space-x-2 items-center md:border-l border-neutral-600"> 
                 <div className="  font-display shrink-0">
                    <div className="flex flex-col sm:flex-row items-center">
                    <a
                          className={`mr-2 hidden md:block border-inset active:scale-[.97] transition px-4 py-3 md:py-3 md:px-6 text-sm  font-semibold text-sm rounded-lg ${
                            headerTransparent ? "text-neutral-900" : "text-neutral-900"
                          }`}
                          href={`tel:${phonenumber}`}
                        ><div className="flex items-center text-neutral-400">Call us today!</div>
                        <span className="text-lg lg:text-xl font-semibold text-white">{phonenumber}</span>
                        </a>
                      {/* <div className="hidden sm:block sm:pr-8 text-center">
                        <a
                          className={`mb-1 block font-bold text-base sm:text-xl lg:text-xl  tracking-tight ${
                            headerTransparent ? "text-neutral-900" : "text-neutral-900"
                          }`}
                          href={`tel:${phonenumber}`}
                        >
                          {phonenumber}
                        </a>
                      </div> */}
                      <div className="flex">
                      <Button
                          onClick={() => setContactFormOpen(true)}
                          className="hidden sm:block md:hidden xl:px-12 xl:py-4 mr-2 !bg-neutral-900"
 color="dark"
 >
                        <span className="text-[13px] lg:text-[15px] sm:text-sm font-medium hidden">Call</span> <PhoneIcon fill="white" className="h-4 w-4 sm:w-4 sm:h-4 ml-1"/>
                        </Button>
                        <Button
                          onClick={() => setContactFormOpen(true)}
                          className="xl:px-12 xl:py-4"
 color="brandGradient"
 >
                        <span className="text-[13px] lg:text-[15px] sm:text-sm font-medium">Free Estimate</span> <ChevronRight className="h-4 w-4 sm:w-4 sm:h-4"/>
                        </Button>
                       
                        <ContactFormModal modalOpen={contactFormOpen} onModalClose={setContactFormOpen} />
                      </div>
                    </div>
                  </div>

                  <div className={`   -my-2 lg:hidden sm:pl-0 sm:m-0`}>

                  <Popover className="lg:hidden flex">
                {({ open }) => (
                  <>
                    <Popover.Button
                    className={`${
                      headerTransparent
                        ? "text-neutral-600"
                        : " text-neutral-100 hover:bg-neutral-800  focus:text-neutral-100  focus:bg-neutral-800"
                    } text-center p-2   rounded-lg  focus:outline-none  transition duration-150 ease-in  bg-black/20`}  aria-label="Toggle site navigation"
                    >
                      {({ open }) =>

open ? (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    className="h-7 w-7 mx-auto"
  >
    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
  </svg>
) : (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    className="h-7 w-7 mx-auto"
  >
    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
  </svg>
)

                     
                      }
                    </Popover.Button>
                    <AnimatePresence initial={false}>
                      {open && (
                        <>
                          {/* <Popover.Overlay
                            static
                            as={motion.div}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                          /> */}
                          <Popover.Panel
                            static
                            as={motion.div}
                            initial={{ opacity: 0, y: -24 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{
                              opacity: 0,
                              y: -24,
                              transition: { duration: 0.2 },
                            }}
                            className="absolute inset-x-0 top-[100%] z-0 origin-top rounded-b-2xl pointer-events-auto  "
                          >

                    
                          
<div className="flex h-full flex-col overflow-y-scroll bg-[#2b2b2b]/95 backdrop-blur-xl shadow-xl">
                       
                            <div className="relative  font-display">

                              <div className="flex px-6 justify-between py-2  shadow-xl relative ">
                                <p className="text-white">Call Us Today!</p>
                                <a
                          className={`block font-bold  block text-brand-600 `}
                          href={`tel:${phonenumber}`}
                        >
                          {phonenumber}
                        </a>
                              </div>
                              {" "}
                              <div className="overflow-auto max-h-[60dvh]">  
                                {items.map((item, i) => {
                                  return <MenuItem key={i} item={item} type={item.type} source={"mobilemenu"} />;
                                })}
                              </div>
                            </div> 
                          </div>
                          </Popover.Panel>
                        </>
                      )}
                    </AnimatePresence>
                  </>
                )}
              </Popover>


                 
                  </div>
                  </div></div>
 
              </div>

              {mainnavigationstyle == "stacked" ? (
                <div className="relative bg-white text-white md:shadow-sm md:border-none z-10 hidden md:block font-display">
                  <div className="flex items-center px-4 md:px-6 md:space-x-10 container mx-auto ">
                    {items.map((item, i) => {
                      return <MenuItem key={i} item={item} type={item.type} />;
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div className={`sticky top-0  z-20 md:hidden py-1 px-2 ${headerTransparent ? "-mb-48" : "bg-brand-600"}`}>
              <div
                className={` shadow-sm md:border-none ${
                  headerTransparent
                    ? "shadow-lg bg-brand-600/90 backdrop-blur-lg rounded-2xl text-brand-900 "
                    : "bg-brand-600  text-brand-900 "
                }`}
              >
                <div className="flex  items-center justify-between px-4 md:px-6  py-3 container mx-auto ">
                  <div>
                    <p className="mb-0 text-white font-display hidden sm:block">Give Us A Call Today</p>
                    <a className={`block font-semibold text-xl font-display text-white`} href={`tel:${phonenumber}`}>
                      {phonenumber}
                    </a>
                  </div>
                  <Button onClick={() => setContactFormOpen(true)} className="bg-brand-800 py-2.5 px-4 -my-1">
                    Free Estimate
                  </Button>
                </div>
              </div>
            </div> */}

            
            
            <div className="hidden bg-brand-700  text-neutral-100">
        {featureList && (
          <div className="mx-auto flex  items-center justify-center gap-3 py-3 md:gap-5">
            {featureList.map((item, i) => {
              return (
                <>
                  {" "}
                  {i > 0 && <span className="text-white">/</span>}
                  <div className="flex  flex-row  items-center" key={i}>
                    {item.icon && (
                      <item.icon
                        className="white-600 mr-2 hidden shrink-0 text-white sm:block"
                        size={16}
                      />
                    )}

                    <p className="text-left  text-xs sm:text-sm font-medium leading-6 text-white  ">
                      {item.item}
                    </p>
                  </div>
                </>
              )
            })}
          </div>
        )}
      </div>
      
  
          </>
        );
      }}
    />
  );
}

export default Navbar;
