import React from "react";
import { Spinner } from "../Spinner"
import { Link } from "gatsby";

const sizeClassnames = {
  xl: "active:scale-[.97] transition px-6 py-4 md:py-4 md:px-8 text-sm  font-semibold md:text-base rounded-xl",
  big: "active:scale-[.97] transition px-4 py-3 md:py-3 md:px-6 text-sm  font-semibold text-sm rounded-xl",
  small: "active:scale-[.97] transition px-2 py-2 text-xs rounded-sm md:text-sm",
};

const colorClassnames = {
  primary:
    "text-white bg-neutral-900  border-neutral-900 active:bg-neutral-900 hover:border-neutral-900 hover:bg-black disabled:text-brand-600 disabled:bg-neutral-700",
 
    outline:
    "text-neutral-800   border-neutral-400 ",
   brand:
    "text-white bg-brand-600  border-brand-600 hover:bg-brand-700 active:bg-brand-600 hover:border-brand-700 hover:bg-black disabled:text-brand-600 disabled:bg-neutral-700",
 
    secondary:
    "text-brand-500 bg-white  border-white  hover:border-brand-100 hover:bg-brand-100 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300",
  red: "text-white bg-brand-600 hover:bg-brand-700 disabled:text-brand-600 disabled:bg-brand-600 focus:ring-4 focus:ring-brand-400",
  accent:
    "text-white bg-accent-500 hover:bg-accent-600 disabled:text-accent-600 disabled:bg-accent-600 focus:ring-4 focus:ring-accent-300",
  link: "text-neutral-800 text-underline focus:ring-4 focus:ring-brand-300",
  primarySecondary:
    "text-brand-500 hover:text-brand-700   border-brand-500 hover:border-brand-700 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300",
  lightSecondary:
    "text-white  hover:text-white   border-neutral-100 hover:border-brand-300 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300",
    lightSecondary:
    "text-white  hover:text-white   border-neutral-100 hover:border-brand-300 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300",

    greenGradient: 'text-white bg-gradient-to-b from-green-500 to-green-700 hover:from-green-500 hover:to-green-700 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-green-500',
   brandGradient: 'text-white bg-gradient-to-b from-brand-600 to-brand-700 hover:from-brand-700 hover:to-brand-800 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300',
   orangeGradient: 'text-white bg-gradient-to-b from-orange-400 to-orange-600 hover:from-orange-500 hover:to-orange-700 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300',
   subtleGray: 'text-gray-800 bg-gradient-to-b from-gray-100 to-gray-100 hover:from-gray-200 hover:to-gray-200 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300',

  };

const Button = ({
  children,
  size = "big",
  color = "primary",
  disabled,
  loading,
  icon,
  className = "",
  transition = true,
  href,
  to,
  ...props
}) => {
  if (href) {
    return (
      <a
        href={href}
        className={`font-display  flex outline-none ${sizeClassnames[size]} ${
          transition ? `transition duration-200 ease-in-out` : ``
        } ${colorClassnames[color]} items-center justify-center ${className}`}
        {...props}
      >
        {" "}
        <span className={loading ? "opacity-0 flex items-center" : `flex items-center`}>
          {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
          {children}
        </span>
      </a>
    );
  } else if (to) {
    return (
      <Link
        to={to}
        className={`font-display  inline-flex outline-none ${sizeClassnames[size]} ${
          transition ? `transition duration-200 ease-in-out` : ``
        } ${colorClassnames[color]} items-center justify-center ${className}`}
        {...props}
      >
        {" "}
        <span className={loading ? "opacity-0 flex items-center" : `flex items-center`}>
          {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
          {children}
        </span>
      </Link>
    );
  }

  return (
    <button
      className={` font-display  flex outline-none ${sizeClassnames[size]} ${
        transition ? `transition duration-200 ease-in-out` : ``
      } ${colorClassnames[color]} items-center justify-center ${className}`}
      {...props}
    >
      <span className={loading ? "opacity-0 flex items-center" : `flex items-center`}>
        {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
        {children}
      </span>

      {loading ? (
        <span className={`absolute`}>
          <Spinner size={size === "small" ? "2" : "4"} />
        </span>
      ) : null}
    </button>
  );
};

export default Button;
