import React from "react";
import styled from "styled-components";

const Logo = ({ classes, singleColor }) => (

 

<svg viewBox="0 0 600 231"  xmlns="http://www.w3.org/2000/svg" className={`${classes ? classes : "text-brand-500"} -my-3`}>
<path d="M334.007 104.397C334.02 111.672 331.142 118.654 326.007 123.807C320.872 128.96 313.9 131.862 306.625 131.875L301.405 131.884C294.13 131.897 287.148 129.019 281.995 123.884C276.842 118.749 273.94 111.777 273.927 104.502L273.912 95.7819C273.899 88.507 276.777 81.525 281.912 76.3719C287.048 71.2189 294.019 68.3168 301.294 68.3041L306.514 68.295C313.789 68.2823 320.771 71.16 325.924 76.2952C331.077 81.4303 333.979 88.4022 333.992 95.6771L334.007 104.397ZM302.11 83.4295C292.25 84.7695 292.12 96.8195 292.88 104.839C293.153 107.693 293.93 110.196 295.21 112.349C298.48 117.869 308.11 118.309 312.07 113.359C315.47 109.129 315.63 101.649 315.11 95.8295C314.41 87.8895 310.84 82.2395 302.11 83.4295Z" fill={`${singleColor ? 'currentColor' : '#d31436'}`}/>
<path d="M424.13 114.98C426.497 116.713 429.243 117.286 432.37 116.7C436.817 115.873 439.383 112.776 440.07 107.41C440.117 107.023 440.337 106.83 440.73 106.83L457.3 106.82C457.767 106.82 457.983 107.053 457.95 107.52C456.47 126.83 440.78 134.56 423.23 131.55C410.09 129.3 402.03 119.56 400.32 106.53C398.27 91.0598 402.98 75.1098 418.76 69.6898C425.66 67.3198 434.96 67.4698 441.83 69.9698C451.443 73.4698 456.733 80.9498 457.7 92.4098C457.704 92.4529 457.699 92.4964 457.685 92.5374C457.671 92.5785 457.648 92.6163 457.618 92.6483C457.587 92.6804 457.551 92.7059 457.51 92.7234C457.469 92.7409 457.425 92.7499 457.38 92.7498H440.26C440.115 92.7508 439.974 92.6984 439.866 92.6024C439.757 92.5065 439.687 92.3739 439.67 92.2298C439.057 86.6631 436.08 83.6965 430.74 83.3298C424.08 82.8698 420.44 87.2698 419.45 93.3198C418.34 100.06 418.14 110.61 424.13 114.98Z" fill={`${singleColor ? 'currentColor' : '#d31436'}`}/>
<path d="M258.19 101.72C258.118 101.753 258.057 101.808 258.017 101.877C257.976 101.946 257.958 102.026 257.965 102.106C257.972 102.185 258.002 102.261 258.053 102.322C258.104 102.384 258.173 102.428 258.25 102.45C263.443 103.923 266.223 107.483 266.59 113.13C266.843 117.063 267.03 121.066 267.15 125.14C267.19 126.77 268.11 127.62 268.85 128.92C268.932 129.066 268.975 129.231 268.973 129.399C268.971 129.567 268.926 129.731 268.841 129.876C268.756 130.02 268.634 130.14 268.489 130.223C268.343 130.307 268.178 130.35 268.01 130.35H250.19C250.029 130.349 249.873 130.292 249.75 130.187C249.628 130.083 249.546 129.939 249.52 129.78C248.793 125.486 248.523 121.303 248.71 117.23C248.943 112.343 246.65 109.763 241.83 109.49C238.917 109.323 236.13 109.286 233.47 109.38C233.01 109.4 232.78 109.64 232.78 110.1L232.85 129.99C232.85 130.037 232.841 130.084 232.823 130.127C232.805 130.171 232.778 130.211 232.745 130.244C232.711 130.278 232.672 130.304 232.628 130.322C232.584 130.34 232.537 130.35 232.49 130.35H214.67C214.623 130.35 214.576 130.34 214.532 130.323C214.489 130.305 214.449 130.28 214.415 130.247C214.382 130.215 214.356 130.176 214.337 130.133C214.319 130.091 214.31 130.046 214.31 130V70.0895C214.31 69.7962 214.46 69.6462 214.76 69.6395C225.08 69.5929 235.41 69.6162 245.75 69.7095C254.04 69.7895 263.49 71.9695 265.91 81.1195C267.98 88.9795 266.32 97.9195 258.19 101.72ZM232.76 84.7895L232.82 95.4895C232.82 95.5771 232.855 95.661 232.917 95.7229C232.979 95.7848 233.063 95.8195 233.15 95.8195L243.55 95.7695C245.064 95.7607 246.512 95.2312 247.577 94.2973C248.643 93.3635 249.237 92.1018 249.23 90.7895V89.3295C249.227 88.6792 249.075 88.036 248.785 87.4367C248.495 86.8375 248.071 86.2938 247.538 85.8369C247.005 85.38 246.373 85.0188 245.678 84.7739C244.984 84.5289 244.24 84.4051 243.49 84.4095L233.09 84.4595C233.003 84.4595 232.919 84.4943 232.857 84.5562C232.795 84.6181 232.76 84.702 232.76 84.7895Z" fill={`${singleColor ? 'currentColor' : '#d31436'}`}/>
<path d="M355.38 130.35H337.54C337.487 130.35 337.435 130.337 337.388 130.313C337.342 130.289 337.302 130.253 337.271 130.21C337.241 130.167 337.221 130.117 337.214 130.065C337.207 130.013 337.212 129.96 337.23 129.91L357.54 69.8502C357.563 69.7844 357.607 69.7277 357.664 69.6882C357.722 69.6487 357.79 69.6284 357.86 69.6302L376.39 69.6202C376.46 69.6184 376.528 69.6387 376.586 69.6782C376.643 69.7177 376.687 69.7744 376.71 69.8402L396.98 129.92C396.997 129.97 397.003 130.023 396.996 130.075C396.988 130.128 396.969 130.177 396.938 130.22C396.908 130.263 396.868 130.299 396.821 130.323C396.774 130.347 396.723 130.36 396.67 130.36L378.71 130.35C378.638 130.348 378.569 130.322 378.513 130.277C378.457 130.232 378.417 130.17 378.4 130.1L376.41 122.62C376.392 122.55 376.353 122.488 376.297 122.443C376.241 122.398 376.172 122.372 376.1 122.37L358.08 122.38C358.008 122.38 357.937 122.403 357.879 122.446C357.822 122.49 357.78 122.551 357.76 122.62L355.7 130.11C355.68 130.18 355.638 130.241 355.58 130.284C355.523 130.328 355.452 130.351 355.38 130.35ZM361.4 109.11C361.386 109.157 361.383 109.206 361.391 109.254C361.4 109.302 361.419 109.347 361.448 109.386C361.478 109.425 361.516 109.457 361.559 109.478C361.603 109.5 361.651 109.511 361.7 109.51L372.5 109.53C372.549 109.531 372.597 109.52 372.64 109.498C372.684 109.477 372.722 109.445 372.751 109.406C372.78 109.367 372.8 109.322 372.808 109.274C372.817 109.226 372.814 109.177 372.8 109.13L367.43 89.6302C367.413 89.5638 367.374 89.505 367.319 89.4631C367.265 89.4211 367.198 89.3983 367.13 89.3983C367.061 89.3983 366.995 89.4211 366.94 89.4631C366.886 89.505 366.847 89.5638 366.83 89.6302L361.4 109.11Z" fill={`${singleColor ? 'currentColor' : '#d31436'}`}/>
<path d="M501.98 89.8001L501.95 70.0001C501.95 69.9515 501.96 69.9034 501.978 69.8585C501.997 69.8136 502.024 69.7728 502.058 69.7385C502.093 69.7041 502.134 69.6769 502.179 69.6583C502.223 69.6397 502.272 69.6301 502.32 69.6301L520.42 69.6201C520.518 69.6201 520.612 69.6591 520.682 69.7285C520.751 69.7979 520.79 69.892 520.79 69.9901L520.8 129.98C520.8 130.078 520.761 130.172 520.692 130.242C520.622 130.311 520.528 130.35 520.43 130.35H502.32C502.272 130.35 502.223 130.341 502.179 130.322C502.134 130.303 502.093 130.276 502.058 130.242C502.024 130.207 501.997 130.167 501.978 130.122C501.96 130.077 501.95 130.029 501.95 129.98L501.98 107.19C501.98 107.092 501.941 106.998 501.872 106.928C501.802 106.859 501.708 106.82 501.61 106.82H484.99C484.892 106.82 484.798 106.859 484.728 106.928C484.659 106.998 484.62 107.092 484.62 107.19L484.66 129.98C484.66 130.029 484.651 130.077 484.632 130.122C484.613 130.167 484.586 130.207 484.552 130.242C484.517 130.276 484.477 130.303 484.432 130.322C484.387 130.341 484.339 130.35 484.29 130.35H466.22C466.172 130.35 466.123 130.341 466.079 130.322C466.034 130.303 465.993 130.276 465.958 130.242C465.924 130.207 465.897 130.167 465.878 130.122C465.86 130.077 465.85 130.029 465.85 129.98L465.86 70.0001C465.86 69.9515 465.87 69.9034 465.888 69.8585C465.907 69.8136 465.934 69.7728 465.968 69.7385C466.003 69.7041 466.044 69.6769 466.089 69.6583C466.133 69.6397 466.182 69.6301 466.23 69.6301L484.29 69.6201C484.388 69.6201 484.482 69.6591 484.552 69.7285C484.621 69.7979 484.66 69.892 484.66 69.9901L484.61 89.8101C484.61 89.9082 484.649 90.0024 484.718 90.0717C484.788 90.1411 484.882 90.1801 484.98 90.1801L501.61 90.1701C501.708 90.1701 501.802 90.1311 501.872 90.0617C501.941 89.9924 501.98 89.8982 501.98 89.8001Z" fill={`${singleColor ? 'currentColor' : '#d31436'}`}/>
<path d="M164.13 90.5696L138.88 109.74C138.828 109.779 138.765 109.8 138.7 109.8L132.59 109.95C132.526 109.952 132.463 109.934 132.411 109.898C132.358 109.862 132.318 109.81 132.297 109.749C132.276 109.689 132.274 109.624 132.293 109.563C132.311 109.501 132.349 109.448 132.4 109.41L164.14 85.4096C164.192 85.3707 164.255 85.3496 164.32 85.3496C164.385 85.3496 164.448 85.3707 164.5 85.4096L196.22 109.42C196.271 109.458 196.309 109.511 196.327 109.573C196.346 109.634 196.344 109.699 196.323 109.759C196.302 109.82 196.262 109.872 196.209 109.908C196.157 109.944 196.094 109.962 196.03 109.96L189.93 109.8C189.865 109.8 189.802 109.779 189.75 109.74L164.49 90.5696C164.438 90.5307 164.375 90.5096 164.31 90.5096C164.245 90.5096 164.182 90.5307 164.13 90.5696Z" fill={`${singleColor ? 'currentColor' : '#d31436'}`}/>
<path d="M163.922 102.196C163.923 102.247 163.913 102.298 163.894 102.345C163.874 102.393 163.846 102.436 163.81 102.472C163.774 102.509 163.731 102.538 163.684 102.558C163.637 102.578 163.586 102.588 163.535 102.589L160.255 102.611C160.204 102.612 160.153 102.602 160.106 102.583C160.058 102.563 160.015 102.535 159.979 102.499C159.942 102.463 159.913 102.42 159.893 102.373C159.873 102.326 159.863 102.275 159.862 102.224L159.838 98.7242C159.838 98.673 159.847 98.6222 159.867 98.5747C159.886 98.5273 159.914 98.4841 159.95 98.4476C159.986 98.4112 160.029 98.3821 160.076 98.3622C160.123 98.3423 160.174 98.3319 160.225 98.3315L163.505 98.3086C163.556 98.3082 163.607 98.318 163.655 98.3373C163.702 98.3565 163.745 98.385 163.782 98.4209C163.818 98.4569 163.847 98.4997 163.867 98.5469C163.887 98.594 163.897 98.6447 163.898 98.6959L163.922 102.196Z" fill={`${singleColor ? 'currentColor' : '#d31436'}`}/>
<path d="M169.075 102.27C169.076 102.347 169.047 102.421 168.994 102.477C168.941 102.532 168.868 102.564 168.791 102.565L165.231 102.634C165.193 102.635 165.155 102.628 165.12 102.614C165.084 102.6 165.052 102.579 165.025 102.553C164.997 102.526 164.975 102.495 164.96 102.46C164.945 102.425 164.937 102.387 164.936 102.349L164.865 98.6501C164.863 98.5732 164.892 98.4989 164.946 98.4435C164.999 98.3881 165.072 98.3561 165.149 98.3546L168.709 98.2862C168.747 98.2855 168.784 98.2922 168.82 98.3061C168.855 98.32 168.888 98.3408 168.915 98.3672C168.943 98.3936 168.965 98.4251 168.98 98.46C168.995 98.4949 169.003 98.5325 169.004 98.5706L169.075 102.27Z" fill={`${singleColor ? 'currentColor' : '#d31436'}`}/>
<path d="M164.058 107.509C164.062 107.646 164.01 107.78 163.915 107.88C163.82 107.98 163.689 108.038 163.551 108.041L160.412 108.118C160.274 108.121 160.141 108.07 160.041 107.975C159.941 107.879 159.883 107.749 159.88 107.611L159.802 104.432C159.799 104.294 159.85 104.16 159.945 104.06C160.04 103.961 160.171 103.903 160.309 103.899L163.448 103.822C163.586 103.819 163.72 103.871 163.819 103.966C163.919 104.061 163.977 104.192 163.981 104.33L164.058 107.509Z" fill={`${singleColor ? 'currentColor' : '#d31436'}`}/>
<path d="M169.087 107.628C169.088 107.684 169.077 107.74 169.056 107.793C169.035 107.845 169.004 107.893 168.965 107.933C168.925 107.973 168.878 108.006 168.826 108.028C168.774 108.05 168.718 108.062 168.662 108.062L165.342 108.097C165.286 108.098 165.23 108.087 165.177 108.066C165.125 108.045 165.077 108.014 165.037 107.974C164.996 107.935 164.964 107.888 164.942 107.836C164.92 107.784 164.908 107.728 164.908 107.671L164.873 104.332C164.872 104.275 164.883 104.219 164.904 104.167C164.925 104.114 164.956 104.067 164.995 104.026C165.035 103.986 165.082 103.954 165.134 103.932C165.186 103.909 165.242 103.898 165.298 103.897L168.618 103.862C168.674 103.862 168.73 103.872 168.783 103.893C168.835 103.914 168.883 103.946 168.923 103.985C168.964 104.025 168.996 104.072 169.018 104.124C169.04 104.176 169.052 104.231 169.052 104.288L169.087 107.628Z" fill={`${singleColor ? 'currentColor' : '#d31436'}`}/>
<path d="M142.45 138.69L142.47 161.39C142.47 161.491 142.43 161.588 142.359 161.659C142.287 161.73 142.191 161.77 142.09 161.77L132.34 161.76C132.239 161.76 132.143 161.72 132.071 161.649C132 161.578 131.96 161.481 131.96 161.38V115.27C131.96 115.169 132 115.073 132.071 115.001C132.143 114.93 132.239 114.89 132.34 114.89L142.12 114.87C142.17 114.87 142.219 114.88 142.265 114.899C142.311 114.918 142.353 114.946 142.389 114.981C142.424 115.017 142.452 115.059 142.471 115.105C142.49 115.151 142.5 115.2 142.5 115.25L142.42 128.94C142.42 129.041 142.46 129.138 142.531 129.209C142.603 129.28 142.699 129.32 142.8 129.32H196.85C196.951 129.32 197.047 129.36 197.119 129.431C197.19 129.503 197.23 129.599 197.23 129.7V161.39C197.23 161.491 197.19 161.588 197.119 161.659C197.047 161.73 196.951 161.77 196.85 161.77H186.95C186.849 161.77 186.753 161.73 186.681 161.659C186.61 161.588 186.57 161.491 186.57 161.39L186.59 138.69C186.59 138.589 186.55 138.493 186.479 138.421C186.407 138.35 186.311 138.31 186.21 138.31H142.83C142.729 138.31 142.633 138.35 142.561 138.421C142.49 138.493 142.45 138.589 142.45 138.69Z" fill="currentColor"/>
<path d="M197 123.83C197 123.957 196.949 124.079 196.859 124.169C196.769 124.259 196.647 124.31 196.52 124.31H186.96C186.833 124.31 186.711 124.259 186.621 124.169C186.531 124.079 186.48 123.957 186.48 123.83V115.27C186.48 115.143 186.531 115.021 186.621 114.931C186.711 114.841 186.833 114.79 186.96 114.79H196.52C196.647 114.79 196.769 114.841 196.859 114.931C196.949 115.021 197 115.143 197 115.27V123.83Z" fill="currentColor"/>
<path d="M84.41 138.849L84.44 161.299C84.4413 161.357 84.4312 161.414 84.4102 161.467C84.3892 161.52 84.3577 161.569 84.3176 161.61C84.2776 161.651 84.2297 161.683 84.1769 161.706C84.1241 161.728 84.0673 161.739 84.01 161.739L74.35 161.729C74.2572 161.729 74.1681 161.691 74.1025 161.624C74.0369 161.556 74 161.465 74 161.369C74.02 152.903 74.0333 143.916 74.04 134.409C74.04 131.199 76.34 129.329 79.34 129.329C94.5 129.316 110.203 129.316 126.45 129.329C126.643 129.329 126.74 129.423 126.74 129.609L126.79 138.029C126.79 138.209 126.703 138.299 126.53 138.299H84.96C84.5933 138.299 84.41 138.483 84.41 138.849Z" fill={`${singleColor ? 'currentColor' : '#d31436'}`}/>
<path d="M519.727 141.769L512.626 155.021V163H508.189V155.021L500.843 141.769H506.072L510.48 151.016L514.7 141.769H519.727Z" fill="currentColor"/>
<path d="M497.552 163H493.116L484.445 147.919V163H480.311V141.769H484.963L493.418 156.591V141.769H497.552V163Z" fill="currentColor"/>
<path d="M470.61 158.636H462.789L461.319 163H456.681L464.258 141.769H469.271L476.79 163H471.979L470.61 158.636ZM469.371 154.977L466.721 146.623L463.984 154.977H469.371Z" fill="currentColor"/>
<path d="M448.824 155.366H444.316V163H439.908V141.769H449.156C451.288 141.769 452.987 142.316 454.255 143.411C455.522 144.505 456.156 146.2 456.156 148.495C456.156 151.002 455.522 152.773 454.255 153.811C452.987 154.848 451.177 155.366 448.824 155.366ZM450.899 150.944C451.475 150.435 451.763 149.628 451.763 148.524C451.763 147.42 451.47 146.632 450.884 146.162C450.308 145.691 449.497 145.456 448.45 145.456H444.316V151.708H448.45C449.497 151.708 450.313 151.453 450.899 150.944Z" fill="currentColor"/>
<path d="M418.411 163H414.277V141.769H420.73L424.59 158.463L428.422 141.769H434.803V163H430.669V148.639C430.669 148.226 430.674 147.65 430.683 146.911C430.693 146.162 430.698 145.586 430.698 145.182L426.679 163H422.372L418.382 145.182C418.382 145.586 418.387 146.162 418.396 146.911C418.406 147.65 418.411 148.226 418.411 148.639V163Z" fill="currentColor"/>
<path d="M399.895 163.591C396.86 163.591 394.541 162.765 392.938 161.113C390.786 159.087 389.711 156.168 389.711 152.355C389.711 148.466 390.786 145.547 392.938 143.598C394.541 141.946 396.86 141.12 399.895 141.12C402.929 141.12 405.248 141.946 406.852 143.598C408.993 145.547 410.064 148.466 410.064 152.355C410.064 156.168 408.993 159.087 406.852 161.113C405.248 162.765 402.929 163.591 399.895 163.591ZM404.086 157.887C405.114 156.59 405.628 154.747 405.628 152.355C405.628 149.974 405.109 148.135 404.072 146.839C403.045 145.533 401.652 144.88 399.895 144.88C398.137 144.88 396.735 145.528 395.689 146.824C394.642 148.121 394.119 149.964 394.119 152.355C394.119 154.747 394.642 156.59 395.689 157.887C396.735 159.183 398.137 159.831 399.895 159.831C401.652 159.831 403.049 159.183 404.086 157.887Z" fill="currentColor"/>
<path d="M383.28 161.416C381.696 162.866 379.67 163.591 377.202 163.591C374.148 163.591 371.747 162.612 370 160.653C368.252 158.684 367.378 155.986 367.378 152.557C367.378 148.851 368.372 145.994 370.36 143.987C372.088 142.239 374.287 141.365 376.957 141.365C380.529 141.365 383.141 142.537 384.793 144.88C385.705 146.195 386.195 147.516 386.262 148.841H381.825C381.537 147.823 381.168 147.055 380.716 146.536C379.91 145.615 378.714 145.154 377.13 145.154C375.516 145.154 374.244 145.807 373.312 147.113C372.381 148.409 371.915 150.248 371.915 152.629C371.915 155.011 372.405 156.797 373.385 157.988C374.374 159.169 375.627 159.76 377.144 159.76C378.7 159.76 379.886 159.251 380.702 158.233C381.153 157.685 381.528 156.864 381.825 155.77H386.219C385.835 158.084 384.855 159.966 383.28 161.416Z" fill="currentColor"/>
<path d="M348.381 162.481C347.209 163.202 345.769 163.562 344.059 163.562C341.246 163.562 338.941 162.587 337.145 160.638C335.273 158.679 334.336 156 334.336 152.6C334.336 149.162 335.282 146.406 337.174 144.332C339.066 142.258 341.567 141.221 344.679 141.221C347.377 141.221 349.543 141.907 351.175 143.281C352.817 144.644 353.758 146.349 353.998 148.394H349.634C349.298 146.944 348.477 145.931 347.171 145.355C346.441 145.038 345.629 144.879 344.736 144.879C343.027 144.879 341.62 145.528 340.516 146.824C339.421 148.111 338.874 150.051 338.874 152.643C338.874 155.255 339.469 157.104 340.66 158.189C341.851 159.274 343.205 159.817 344.722 159.817C346.21 159.817 347.43 159.389 348.381 158.535C349.331 157.67 349.917 156.542 350.138 155.15H345.226V151.606H354.07V163H351.132L350.685 160.35C349.831 161.358 349.062 162.069 348.381 162.481Z" fill="currentColor"/>
<path d="M330.325 163H325.889L317.218 147.919V163H313.083V141.769H317.736L326.191 156.591V141.769H330.325V163Z" fill="currentColor"/>
<path d="M303.714 141.769H308.122V163H303.714V141.769Z" fill="currentColor"/>
<path d="M300.194 145.528H289.549V150.411H298.868V154.098H289.549V163H285.141V141.797H300.194V145.528Z" fill="currentColor"/>
<path d="M270.687 163.591C267.653 163.591 265.334 162.765 263.73 161.113C261.579 159.087 260.503 156.168 260.503 152.355C260.503 148.466 261.579 145.547 263.73 143.598C265.334 141.946 267.653 141.12 270.687 141.12C273.722 141.12 276.041 141.946 277.645 143.598C279.786 145.547 280.857 148.466 280.857 152.355C280.857 156.168 279.786 159.087 277.645 161.113C276.041 162.765 273.722 163.591 270.687 163.591ZM274.879 157.887C275.906 156.59 276.42 154.747 276.42 152.355C276.42 149.974 275.902 148.135 274.865 146.839C273.837 145.533 272.445 144.88 270.687 144.88C268.93 144.88 267.528 145.528 266.481 146.824C265.434 148.121 264.911 149.964 264.911 152.355C264.911 154.747 265.434 156.59 266.481 157.887C267.528 159.183 268.93 159.831 270.687 159.831C272.445 159.831 273.842 159.183 274.879 157.887Z" fill="currentColor"/>
<path d="M246.842 163.591C243.807 163.591 241.488 162.765 239.884 161.113C237.733 159.087 236.658 156.168 236.658 152.355C236.658 148.466 237.733 145.547 239.884 143.598C241.488 141.946 243.807 141.12 246.842 141.12C249.876 141.12 252.195 141.946 253.799 143.598C255.94 145.547 257.011 148.466 257.011 152.355C257.011 156.168 255.94 159.087 253.799 161.113C252.195 162.765 249.876 163.591 246.842 163.591ZM251.033 157.887C252.061 156.59 252.574 154.747 252.574 152.355C252.574 149.974 252.056 148.135 251.019 146.839C249.991 145.533 248.599 144.88 246.842 144.88C245.084 144.88 243.682 145.528 242.635 146.824C241.589 148.121 241.065 149.964 241.065 152.355C241.065 154.747 241.589 156.59 242.635 157.887C243.682 159.183 245.084 159.831 246.842 159.831C248.599 159.831 249.996 159.183 251.033 157.887Z" fill="currentColor"/>
<path d="M224.264 154.675H219.683V163H215.348V141.769H225.762C227.25 141.797 228.393 141.98 229.19 142.316C229.997 142.652 230.679 143.147 231.236 143.8C231.697 144.337 232.061 144.933 232.33 145.586C232.599 146.239 232.734 146.983 232.734 147.818C232.734 148.827 232.479 149.821 231.97 150.8C231.461 151.77 230.621 152.457 229.449 152.86C230.429 153.254 231.12 153.815 231.524 154.545C231.937 155.265 232.143 156.37 232.143 157.858V159.284C232.143 160.254 232.181 160.912 232.258 161.258C232.374 161.805 232.642 162.208 233.065 162.468V163H228.182C228.047 162.53 227.951 162.151 227.894 161.863C227.779 161.267 227.716 160.657 227.707 160.033L227.678 158.06C227.659 156.706 227.409 155.803 226.929 155.352C226.458 154.9 225.57 154.675 224.264 154.675ZM226.958 150.814C227.841 150.411 228.283 149.614 228.283 148.423C228.283 147.137 227.855 146.272 227.001 145.831C226.521 145.581 225.8 145.456 224.84 145.456H219.683V151.16H224.71C225.709 151.16 226.458 151.045 226.958 150.814Z" fill="currentColor"/>
</svg>
 
);

export default Logo;

